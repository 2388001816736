.inner-banner {
    position: relative;
    min-height: 56.25vw;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    background-image: url(../assets/img_blur-1.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #2b2b2b;
    color: #fff;

    @media screen and (min-width: 48em) { /* 768px */
        background: url(../assets/banner-background_v1.svg);
    }

    @media only screen and (min-width: 62em) { /* 992px */
        min-height: calc(4vw + 92vw * 0.3333);
        background-color: $color-primary;
        background-position: top -100px right;
        background-image: url(../assets/banner-background_v1.svg);
    }

    @media only screen and (min-width: 90em) { /* 1440px */
        /*
            half the screen width
            less
            half the container width
            (is the container outside gutter)
            plus
            33.33% of the container width (33.33% of 1140px) * 0.5625 (19x9 ratio)
        */
        min-height: calc(50vw - 570px + 214px);
    }

    @media screen and (min-width: 100em) { /* 1600px */
        min-height: calc(50vw - 720px + 270px);
    }

    @media screen and (min-width: 120em) { /* 1920px */
        min-height: calc(50vw - 720px + 180px);
    }

    @media screen and (min-width: 150em) { /* 2400px */
        background-position: top right;
        min-height: 570px;
    }

    &__img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(35,64,143);
        background: -moz-linear-gradient(183deg, rgba(35,64,143,1) 0%, rgba(0,0,0,1) 100%);
        background: -webkit-linear-gradient(183deg, rgba(35,64,143,1) 0%, rgba(0,0,0,1) 100%);
        background: linear-gradient(183deg, rgba(35,64,143,1) 0%, rgba(0,0,0,1) 100%);
        z-index: 0;
        opacity: 0.5;

        @supports(mix-blend-mode: multiply) {
            mix-blend-mode: multiply;
            opacity: 0.75;
        }

        @media screen and (min-width: 48em) { /* 768px */
            opacity: 1;
            background: none;
            background-image: url(../assets/img_blur-1.jpg);
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            @supports(mix-blend-mode: multiply) {
                mix-blend-mode: normal;
                opacity: 1;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgb(35,64,143);
                background: -moz-linear-gradient(183deg, rgba(35,64,143,1) 0%, rgba(0,0,0,1) 100%);
                background: -webkit-linear-gradient(183deg, rgba(35,64,143,1) 0%, rgba(0,0,0,1) 100%);
                background: linear-gradient(183deg, rgba(35,64,143,1) 0%, rgba(0,0,0,1) 100%);
                z-index: 0;
                opacity: 0.5;
            }

            @supports(mix-blend-mode: multiply) {
                &:after {
                    mix-blend-mode: multiply;
                    opacity: 0.66;
                }
            }
        }

        @media only screen and (min-width: 62em) { /* 992px */
            right: calc(4vw + 92vw * 0.6667 + 40px);

            &:after {
                content: none;
            }
        }

        @media only screen and (min-width: 90em) { /* 1440px */
            /*
                half the screen width
                less
                half the container width
                (is the container outside gutter)
                plus
                66.67% of the container width (66.67% of 1140px)
                + 60px of padding to space image away from banner content
            */
            right: calc(50vw - 570px + 820px);
        }

        @media only screen and (min-width: 100em) { /* 1600px */
            /*
                half the screen width
                less
                half the container width
                (is the container outside gutter)
                plus
                58.333% of the container width (58.333% of 1140px)
                + 92px of padding to space image away from banner content
            */
            right: calc(50vw - 570px + 757px);
        }

    }

    .container {
        position: relative;
        z-index: 2;
    }

    &__content {
        h1 {
            margin-top: 0.5em;
            margin-bottom: 0.25em;
        }

        @media screen and (min-width: 48em) { /* 768px */
            p {
                font-size: 2rem;
            }
        }

        @media only screen and (min-width: 62em) { /* 992px */
            margin-left: 33.33%;
        }

        @media only screen and (min-width: 100em) { /* 1600px */
            margin-left: 41.66666667%;
        }
    }
}
