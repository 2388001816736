.sidebar--related-links {
    margin-bottom: 25px;
}

.sidebar--related-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.sidebar--related-links a {
    text-decoration: none;
    color: #2a333e;
    display: block;
    padding: 9px 0;
}


/* - Depth 1 - */
.sidebar--related-links li.depth1 {
    border-bottom: 1px solid #e6e1e1;
    position: relative;
}
.sidebar--related-links li.depth1:last-child {
    border-bottom: none;
}
.sidebar--related-links li.depth1 > a {
    padding: 9px 0;
}

/* On */
.sidebar--related-links li.depth1.on > a {
    border-left: 3px solid #35ab75;
    padding-left: 20px;
}


/* - Depth 2 - */
.sidebar--related-links li.depth2 {
    font-size: 1.4rem;
}
.sidebar--related-links li.depth2 a:before {
    content: "- ";
}
.sidebar--related-links li.depth2 a {
    padding: 3px 0 3px $spacing--large;
}

/* Hover */
.sidebar--related-links li > a:hover {
    color: #000;
}

/* On */
.sidebar--related-links li.depth2.on > a {
    color: #35ab75;
    font-weight: bold;
}

