.section--locations-panel {
    background-color: $color-primary;
    background-image: url(../assets/banner-background_v2.svg);
    background-size: cover;
    color: $color-white;

    h2, h3, h4, h5, h6 {
        color: inherit;
    }

    ul {
        list-style-type: none;
        padding-left: 0;
        text-align: center;
    }
}

.section--testimonials + .section--locations-panel {
    border-top: 0.5px solid #fff;
}

.section--pre-footer {
    background-color: $color-grey-01;
    position: relative;

    .col-md-4 {
        margin-bottom: 32px;

        @media screen and (min-width: 62em) { /* 992px */
            margin-bottom: 0;
        }
    }
}

.footer__heading--linkslist {
    @include line-deco;
}

.footer-subtitle {
    font-weight: $bold-fw;
    font-size: $font-regular;
    margin-bottom: 0.33em;
}

.footer__contact-details {
    margin-bottom: 0.5em;
}

.section--footer {
    background: $color-secondary;
    background: -moz-linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
    background: linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
    color: $color-white;
    @include reverse-text;
    padding: $spacing--xlarge 0;
    text-align: center;
}

.footer__text {
    padding-top: $spacing--medium;
    padding-bottom: $spacing--medium;
}

.footer__text p {
    margin: 0;

    @media screen and (min-width: 35em) { /* 560px */
        display: inline;

        &:before {
            content: "\2003";
        }

        &:first-child:before {
            content: none;
        }
    }
}

.footer__logos {
    border: 1px solid $color-grey-02;
    position: relative;
    padding-top: 100%;
    background-color: transparent;
    display: block;
}

.footer__logos img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    max-width: 66%;
}

@media screen and (min-width: 75em) {
    @supports(filter: grayscale(1)) {
        .footer__logos img {
            filter: grayscale(1);
            transition: filter 150ms ease-in-out;
        }

        .footer__logos:hover img,
        .footer__logos:focus img {
            filter: grayscale(0);
        }
    }
}

.footer__logos--brand-sa {
    margin-bottom: 1rem;
}

.footer__logos--airah {
    height: 100%;
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section--pre-footer .block-list {
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        -webkit-column-gap: 40px;
           -moz-column-gap: 40px;
                column-gap: 40px;
    }
    .section--footer {
        text-align: auto;
    }
    .footer__text {
        padding: 0;
    }
}


.section--footer {
    .social-list__item {
        margin-left: 8px;
        margin-right: 8px;
    }
}
