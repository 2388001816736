.enquiry-form {
    background-color: $color-primary;
    color: $color-white;
    padding: 32px;

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5 {
        line-height: 1.2;
        color: $color-white;
    }

    h1, .h1 {
        font-size: 4rem;
    }

    h2, .h2 {
        font-size: 3.2rem;
        font-weight: $light-fw;
    }

    h3, .h3 {
        font-size: 2.4rem;
        color: $color-grey-06;
    }

    h4, .h4 {
        font-size: 2rem;
    }

    h5, .h5 {
        font-size: 1.8rem;
    }

    &__cta {
        p {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: -5px;
            align-items: center;

            a {
                @include button;
                margin-bottom: 5px;
            }
        }
    }
}


.cta-item + .enquiry-form {
    margin-top: 40px;
}
