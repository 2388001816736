.post-list__item {
    margin-bottom: 1em;
    padding: .5em 0;
    border-bottom: 1px solid #d6d6d6;
}

.post-sidebar-section {
    margin: 0 0 32px;
}

.post-sidebar-archive-list,
.post-sidebar-related-list {
    list-style: none;
    padding: 0;
}

.js .sidebar-linklist__item__sublist {
    display: none;
}

.post-view__image {
    margin-bottom: 16px;
}

.post-view__header {
    text-transform: uppercase;
    font-size: .95em;
}

.sidebar-linklist {
    margin: 0;
    padding: 0;
}

.sidebar-linklist__item {
    border-bottom: 1px solid #d6d6d6;
    position: relative;
}

.sidebar-linklist__item__button {
    display: block;
    background: none;
    border: none;
    padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
    content: ">";
    position: absolute;
    left: 0;
    color: #000;
    top: calc(10px + 0.1em);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: center;
            transform-origin: center;
}

.post-sidebar-archive-list__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
    left: .5em;
}

.post-sidebar-archive-list__item--expanded .sidebar-linklist__item__button:before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-linklist__item__sublist__item {
    list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
    content: "\2013\00a0";
    color: #000;
}

.sidebar-linklist__item__sublist__item__link {
    display: block;
    text-decoration: none;
    padding: .25em .25em .25em 1.5em;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
    background: rgba(0,0,0,.05);
}



/* Post hub */
.post-hub__item--hidden,
.post-hub--unfiltered .post-hub__item--hidden,
.post-hub--filtered .post-hub__item--filtered-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.post-hub--unfiltered .post-hub__item--animate {
    animation: fadeIn 300ms ease;
    animation-fill-mode: forwards;
}

.post-hub--filtered .post-hub__item--filtered-pre-visible {
    opacity: 0;
}

.post-hub--unfiltered .post-hub__item--animate-grow,
.post-hub--filtered .post-hub__item--filtered-visible {
    animation: growInFade 350ms ease;
    animation-fill-mode: forwards;
}

.post-hub__item__image-loading .post-hub__img {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.post-hub__img-link {
    background-color: #f0f0f0;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    display: block;
}

.post-hub__img,
.post-hub__img-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.post-hub__img-placeholder {
    filter: blur(6px);
    transform: scale(1.05);
    transition: all 500ms ease;
    z-index: 1;
}

.post-hub__img-link-fallback .post-hub__img {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    width: 66.66%;
    max-width: 240px;
}

.post-hub__item__image-loaded .post-hub__img-placeholder {
    opacity: 0;
    transform: none;
    transition-duration: 1.5s;
}



/* ---- Category lists ---- */

.post-hub-filterlist {
    list-style-type: none;
    padding: 0;
    font-size: 0.85em;
    text-transform: uppercase;
}

.field-element.field-element--post-hub-filterlist {
    margin-bottom: 35px
}

.js-post-hub-filterlist .js-post-hub-filterlist__select {
    margin-bottom: 24px;
}

/* Hides the list if js is working, defaults to working list if not */
@media screen and (max-width: 37.4375em) {/* 599px */
    .js .js-post-hub-filterlist .post-hub-filterlist {
        display: none;
    }
}

@media screen and (min-width: 37.5em) {/* 600px */
    .post-hub-filterlist {
        text-align: center;
    }

    .post-hub-filterlist__item {
        display: inline-block;
    }

    .js-post-hub-filterlist__select {
        display: none !important;
    }
}
