

.row--vertical-gutters *[class*="col-xs"],
.row--vertical-gutters *[class*="col-sm"],
.row--vertical-gutters *[class*="col-md"],
.row--vertical-gutters *[class*="col-lg"] {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

/* Non-padded col items */
.row-0 {
    margin-left: 0;
    margin-right: 0;

    > .col-xs,
    > .col-xs-1,
    > .col-xs-2,
    > .col-xs-3,
    > .col-xs-4,
    > .col-xs-5,
    > .col-xs-6,
    > .col-xs-7,
    > .col-xs-8,
    > .col-xs-9,
    > .col-xs-10,
    > .col-xs-11,
    > .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
    }
}


/* Revert vertical alignment */
@media screen and (min-width: 48em) {
    .normal-sm {
        align-items: normal;
    }
}

@media screen and (min-width: 62em) { /* 992px */
    .normal-md {
        align-items: normal;
    }

    .col-md-pr {
        padding-right: calc(4.166% + 1rem);
    }

    .row-0 > .col-md-pr {
        padding-right: 4.166%;
    }
}

/* BP large */
@media screen and (min-width: 75em) { /* 1200px */
    .row-0 > .col-lg-pr0 {
        padding-right: 0;
    }
}

.row-gap-lg {
    margin-left: -16px;
    margin-right: -16px;
}

.row-gap-lg > div {
    padding-left: 16px;
    padding-right: 16px;
}
