
.section--nav {
    background: $color-white;
    padding: 0;
}


/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/


/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

#frankenmenu-list a {
    text-decoration: none;
    display: block;
    font-size: $font-regular;
}


/** Desktop menu only
===================================*/
@media screen and (min-width: 992px) {

    #frankenmenu {
        z-index: 1000;
    }

    #frankenmenu-list {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        margin-left: -8px;
        margin-right: -8px;
    }
    #mobile-header {
        display: none;
    }

    /* ---- All level menu items ---- */
    #frankenmenu-list .menu-item {
        position: relative;
    }
    #frankenmenu-list .sub-menu {
        display: none;
        min-width: 200px;
        position: absolute;
        -webkit-box-shadow: 0 6px 6px 0 rgba(0,0,0,0.25);
                box-shadow: 0 6px 6px 0 rgba(0,0,0,0.25);
        z-index: 1;
        min-width: 250px;
        max-width: 350px;
        text-align: left;
        top: 0;
        left: 100%;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1 {
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0;
                flex: 0 1 auto;
    }

    #frankenmenu-list > .menu-item-depth1 {
        text-align: center;
        margin-left: 8px;
        margin-right: 8px;
    }

    #frankenmenu-list .menu-item-depth1 > a {
        display: block;
        color: $color-grey-07;
        padding: 0;
        font-weight: $primary-fw;
        position: relative;
    }

    #frankenmenu-list .menu-item-depth1 > a:before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        right: 50%;
        height: 2px;
        background-color: $color-primary;
        transition: all 200ms ease-in-out;
    }

    /* Hover */
    #frankenmenu-list .menu-item-depth1 > a:hover,
    #frankenmenu-list .menu-item-depth1 > a:focus,
    #frankenmenu-list .menu-item-depth1.frankenhover > a,
    #frankenmenu-list .menu-item-depth1.frankenhold > a {
        color: $color-primary;
    }

    #frankenmenu-list .menu-item-depth1 > a:hover:before,
    #frankenmenu-list .menu-item-depth1 > a:focus:before,
    #frankenmenu-list .menu-item-depth1.frankenhover > a:before,
    #frankenmenu-list .menu-item-depth1.frankenhold > a:before {
        left: 0;
        right: 0;
    }

    /* Current item */
    #frankenmenu-list .menu-item-depth1.menu-current-item > a {
        color: $color-accent-01;
    }

    #frankenmenu-list .menu-item-depth1.menu-current-item > a:before {
        background-color: $color-accent-01;
        left: 0;
        right: 0;
    }


    /* Ancestor item */
    #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
        background-color: darken($color-secondary, 3%);
        color: #FFF;
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu a {
        border-top: 1px solid $color-grey-01;
        padding: 10px 18px 12px;
        color: inherit;
        font-size: 1.5rem;
        line-height: 1.8rem;
        background-color: $color-white;
        -webkit-transition: all 200ms ease;
        -o-transition: all 200ms ease;
        transition: all 200ms ease;
        font-weight: $primary-fw;
    }

    #frankenmenu-list .sub-menu .menu-item-depth1 > a {
        font-size: 1.4rem;
    }

    #frankenmenu-list .sub-menu .menu-item-depth1 > a:before {
        content: none;
    }

    #frankenmenu-list .sub-menu .menu-item:first-child a {
        border-top: none;
    }

    #frankenmenu-list .sub-menu .menu-item > a:hover,
    #frankenmenu-list .sub-menu .menu-item > a:focus,
    #frankenmenu-list .sub-menu .menu-item.frankenhover > a,
    #frankenmenu-list .sub-menu .menu-item.frankenhold > a {
        background: $color-primary;
        color: $color-white;
    }

    #frankenmenu-list .frankenhold > .sub-menu {
        display: block !important;
        opacity: 1 !important;
    }

    /* ---- First level submenus ---- */
    #frankenmenu-list .sub-menu-depth1 {
        top: calc(100% + 16px);
        left: auto;
    }

    /* ---- Right aligned submenus ---- */
    #frankenmenu-list .sub-menu.submenu-right-align,
    #frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
        left: auto;
        right: 100%;
    }
    #frankenmenu-list .sub-menu-depth1.submenu-right-align {
        left: auto;
        right: 0;
    }


    /* ---- Sub-navigation ---- */
    #frankenmenu-list .menu-item-subnav {
        display: none;
    }
    #frankenmenu-subnav {
        float: right;
    }
    #frankenmenu-subnav-list,
    #frankenmenu-subnav-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #frankenmenu-subnav-list .menu-item-depth1 {
        display: inline-block;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a {
        text-decoration: none;
        padding: 5px 5px;
        display: block;
        color: inherit;
    }
    #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
        text-decoration: underline;
    }
    #frankenmenu-subnav-list .sub-menu {
        display: none;
    }

    /* ---- Moved elements ---- */
    .frankenmoved {
        display: none;
    }

    /* ---- Megamenu ---- */
    #frankenmenu-list .mega-menu {
        left: 0;
        right: 0;
        background-color: #FFF;
        text-align: left;
        top: 100%;
        position: absolute;
        border-bottom: 4px solid #D8D8D8;
        z-index: 51;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        width: 1px;
    }
    /* Remove relative positioning for mega menu */
    .frankenmenu-mega #frankenmenu-list .menu-item {
        position: static;
    }
    #frankenmenu-list .menu-item.frankenhover .mega-menu,
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        padding: 40px 0;
        width: auto;
        height: auto;
        clip: auto;
        overflow: visible;
        margin: auto;
    }
    #frankenmenu-list .menu-item.frankenhold .mega-menu {
        display: block !important;
        height: auto !important;
    }

    /* Megamenu preview */
    #frankenmenu-list .mega-menu-preview {
        float: left;
        color: #FFF;
        padding: 18px 20px;
        background-color: #6F6F6F;
        width: 22%;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-preview-image {
        height: 120px;
        background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
        background-size: cover;
    }
    #frankenmenu-list .mega-menu-preview-text {
        margin-bottom: 0;
        line-height: 1.2;
    }

    /* Megamenu columns */
    #frankenmenu-list .mega-menu .mega-menu-columns {
        float: left;
        width: 75%;
    }
    #frankenmenu-list .mega-menu-column {
        width: 31.33333333%;
        float: left;
        margin-right: 3%;
    }
    #frankenmenu-list .mega-menu-column:last-child {
        margin-right: 0;
    }

    /* Megamenu submenus */
    #frankenmenu-list .mega-menu-submenu .menu-item {
        border-top: 1px solid #D8D8D8;
    }
    #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
        border-bottom: 1px solid #D8D8D8;
    }
    #frankenmenu-list .mega-menu-submenu a {
        color: #000;
        padding: 5px 0;
    }


    /* navigation items on mobile */
    #frankenmenu-list .nav__mobile-item {
        display: none;
    }
}

@media screen and (min-width: 75em) { /* 1200px */
    #frankenmenu-list {
        margin-left: -12px;
        margin-right: -12px;
    }

    #frankenmenu-list > .menu-item-depth1 {
        margin-left: 12px;
        margin-right: 12px;
    }

    #frankenmenu-list .menu-item-depth1 > a {
        letter-spacing: 1px;
    }

    #frankenmenu-list .sub-menu .menu-item-depth1 > a {
        letter-spacing: 0;
    }
}

/** Mobile menu only
===================================*/
@media all and (max-width: 991px) {

    /* Prevents font-boosting of menu items on Android */
    #frankenmenu * {
        max-height: 999999px;
    }

    #wrap {
        margin-top: 40px;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: #FFF;
    }
    #frankenmenu {
        top: 0;
        position: fixed;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 100%;
        background-color: $color-white;
        border-left: 1px solid $color-grey-01;
        width: 70%;
        z-index: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        padding-top: 40px;
        box-shadow: 0 -2px 20px rgba(0,0,0,0);
    }
    #frankenmenu .container {
        position: relative;
        padding: 0;
        max-width: none;
        width: 100%;
        margin: 0;
        opacity: 0;
    }

    /* ---- Mobile header ---- */
    #mobile-header {
        background: $color-secondary;
        background: -moz-linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
        background: -webkit-linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
        background: linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
        height: 40px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }
    #mobile-menu-button {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        outline: none;
        border: none;
        padding: 0;
        text-indent: -9999px;
        width: 40px;
        height: 36px;
        margin-top: 2px;
        position: relative;
        background: none;
    }

    .mobile-menu-button-top,
    .mobile-menu-button-middle,
    .mobile-menu-button-bottom {
        height: 3px;
        background-color: #fff;
        left: 6px;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
        -webkit-animation-duration: 400ms;
        animation-duration: 400ms;
        position: absolute;
        display: block;
        right: 6px;
    }
    .mobile-menu-button-top {
        top: 8px;
    }
    .mobile-menu-button-middle {
        top: 17px;
    }
    .mobile-menu-button-bottom {
        top: 26px;
    }

    /* Animations active */
    .frankenmenu-mob-menu-animations .mobile-menu-button-top {
        -webkit-animation-name: mobileMenuBtnTopClose;
        animation-name: mobileMenuBtnTopClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
        -webkit-animation-name: mobileMenuBtnMiddleClose;
        animation-name: mobileMenuBtnMiddleClose;
    }
    .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
        -webkit-animation-name: mobileMenuBtnBottomClose;
        animation-name: mobileMenuBtnBottomClose;
    }

    /* Close button */
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
        -webkit-animation-name: mobileMenuBtnTopOpen;
        animation-name: mobileMenuBtnTopOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
        -webkit-animation-name: mobileMenuBtnMiddleOpen;
        animation-name: mobileMenuBtnMiddleOpen;
    }
    .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
        -webkit-animation-name: mobileMenuBtnBottomOpen;
        animation-name: mobileMenuBtnBottomOpen;
    }



    @-webkit-keyframes mobileMenuBtnTopClose {
        0% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }



    @keyframes mobileMenuBtnTopClose {
        0% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @-webkit-keyframes mobileMenuBtnTopOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @keyframes mobileMenuBtnTopOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, 9px);
            transform: translate(0, 9px);
        }
        100% {
            -webkit-transform: translate(0, 9px) rotate(45deg);
            transform: translate(0, 9px) rotate(45deg);
        }
    }
    @-webkit-keyframes mobileMenuBtnBottomClose {
        0% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @keyframes mobileMenuBtnBottomClose {
        0% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
    }
    @-webkit-keyframes mobileMenuBtnBottomOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @keyframes mobileMenuBtnBottomOpen {
        0% {
            -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
        }
        50%, 70% {
            -webkit-transform: translate(0, -9px);
            transform: translate(0, -9px);
        }
        100% {
            -webkit-transform: translate(0, -9px) rotate(-45deg);
            transform: translate(0, -9px) rotate(-45deg);
        }
    }
    @-webkit-keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @keyframes mobileMenuBtnMiddleClose {
        0% {
            opacity: 0;
        }
        50%, 70%, 100% {
            opacity: 1;
        }
    }
    @-webkit-keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }
    @keyframes mobileMenuBtnMiddleOpen {
        0% {
            opacity: 1;
        }
        50%, 70%, 100% {
            opacity: 0;
        }
    }


    /* ---- All level menu items ---- */
    #frankenmenu-list, #frankenmenu-list ul {
        width: 100%;
    }
    #frankenmenu-list .menu-item {
        position: relative;
        border-top: 1px solid $color-grey-01;
        float: none;
        width: 100%;
    }
    #frankenmenu-list a {
        min-height: 39px;
        width: 100%;
        padding: 9px 85px 9px 20px;
        color: inherit;
    }
    #frankenmenu-list .menu-item.menu-current-item > a {
        background-color: $color-primary;
        color: $color-white;
    }

    /* ---- First level menu ---- */
    #frankenmenu-list .menu-item-depth1:last-child {
        border-bottom: 1px solid $color-grey-01;
    }
    #frankenmenu-list .menu-item-depth1 > a {
        font-size: 1.5rem;
    }

    /* ---- Submenus ---- */
    #frankenmenu-list .sub-menu {
        display: none;
    }
    #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
        display: block;
    }

    /* Depth differences */
    #frankenmenu-list .menu-item-depth1 > a {
        padding-left: 20px;
        background-color: $color-white;
    }
    #frankenmenu-list .menu-item-depth2 a {
        font-size: 1.4rem;
    }
    #frankenmenu-list .menu-item-depth2 > a  {
        padding-left: 20px;
        background-color: rgba(0,0,0,0.1);
    }
    #frankenmenu-list .menu-item-depth3 > a  {
        padding-left: 35px;
        background-color: rgba(0,0,0,0.2);
    }
    #frankenmenu-list .menu-item-depth4 > a  {
        padding-left: 50px;
        background-color: rgba(0,0,0,0.4);
    }
    #frankenmenu-list .menu-item-depth5 > a  {
        padding-left: 50px;
        background-color: rgba(0,0,0,0.5);
    }
    #frankenmenu-list .menu-item-depth6 > a  {
        padding-left: 50px;
        background-color: rgba(0,0,0,0.6);
    }

    /* Expand buttons */
    .submenu-toggle {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: none;
        color: $color-primary;
        font-size: 2.3rem;
        width: 55px;
        background: none;
        height: 39px;
        display: block;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        padding: 0;
    }
    .submenu-toggle:before,
    .submenu-toggle:after {
        content: " ";
        width: 12px;
        height: 3px;
        display: block;
        position: absolute;
        background-color: $color-primary;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 22px;
        -webkit-transition: all 400ms ease;
        -o-transition: all 400ms ease;
        transition: all 400ms ease;
    }
    .submenu-toggle:before {
        -webkit-transform: rotate(45deg) translateX(-5px);
        -ms-transform: rotate(45deg) translateX(-5px);
        transform: rotate(45deg) translateX(-5px);
    }
    .submenu-toggle:after {
        -webkit-transform: rotate(-45deg) translateX(5px);
        -ms-transform: rotate(-45deg) translateX(5px);
        transform: rotate(-45deg) translateX(5px);
    }
    .menu-item-submenu-open > .submenu-toggle:before,
    .menu-item-submenu-open > .submenu-toggle:after {
        top: 15px;
    }
    .menu-item-submenu-open > .submenu-toggle:before {
        -webkit-transform: rotate(-45deg) translateX(-5px);
        -ms-transform: rotate(-45deg) translateX(-5px);
        transform: rotate(-45deg) translateX(-5px);
    }
    .menu-item-submenu-open > .submenu-toggle:after {
        -webkit-transform: rotate(45deg) translateX(5px);
        -ms-transform: rotate(45deg) translateX(5px);
        transform: rotate(45deg) translateX(5px);
    }

    /* ---- Sub-navigation ---- */
    #frankenmenu-subnav-list {
        display: none;
    }

    /* ---- Menu positions ---- */

    /* Left */
    .frankenmenu-mobile-pos-left #frankenmenu {
        left: 0;
    }
    .frankenmenu-mobile-pos-left #frankenmenu .container {
        -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
        transform: translate3d(-10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
        -webkit-transform: translate3d(70%, 0, 0);
        transform: translate3d(70%, 0, 0);
    }

    /* Right */
    .frankenmenu-mobile-pos-right #frankenmenu {
        right: -70%;
    }
    .frankenmenu-mobile-pos-right #frankenmenu .container {
        -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
        transform: translate3d(10%, 0, 0) scale(0.9);
    }
    .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
        opacity: 0.66;
    }
    .frankenmenu-mobile-pos-right #mobile-menu-button {
        float: right;
    }

    /* ---- Transitions ---- */
    #frankenmenu,
    #wrap,
    #frankenmenu .container {
        -webkit-transition: all 350ms ease;
        -o-transition: all 350ms ease;
        transition: all 350ms ease;
    }
    .js #frankenmenu {
        opacity: 1;
    }

    /* Menu open */
    .frankenmenu-mob-menu-visible #frankenmenu {
        right: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        box-shadow: 0 -2px 20px rgba(0,0,0,0.2);
    }
    .frankenmenu-mob-menu-visible #frankenmenu .container {
        right: 0;
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        transform: translate3d(0, 0, 0) scale(1);
    }

    /* ---- Moved elements ---- */
    .frankenmove {
        display: none;
    }

    /* ---- Megamenu ---- */
    .mega-menu-preview {
        display: none;
    }
    .mega-menu-preview-image {
        background-image: none !important;
    }
    .mega-menu-column-title {
        color: #FFF;
        font-size: $font-regular;
        padding: 3px 20px;
        margin: 0;
        background: rgba(0,0,0,0.3);
    }
    #frankenmenu-list .mega-menu {
        display: none;
    }
    #frankenmenu-list .mega-menu ul {
        display: block;
    }

    /* navigation items on mobile */
    #frankenmenu-list .nav__mobile-item {
        display: block;

        a {
            padding-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        svg {
            margin-right: 8px;
            width: 20px;
            height: 20px;

            path {
                fill: rgba($color-primary, 0.8);
            }
        }
    }

}

@media screen and (min-width: 100em) { /* 1600px */
    #frankenmenu-list a {
        font-size: 1.8rem;
    }
}
