.site-introduction {
    text-align: center;

    > *:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: 48em) { /* 768px */
        font-size: 2.4rem;
        line-height: normal;
        font-weight: $light-fw;
    }
}
