.section--affiliated-entities {
    position: relative;
    background-color: $color-primary;

    h2 {
        color: $color-white;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 50vw;
        top: 0;
        bottom: 0;
        background-color: $color-primary;
    }

    &:before {
        right: 100%;
    }

    &:after {
        left: 100%;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        background-color: $color-white;

        &:before,
        &:after {
            content: none;
        }

        h2 {
            color: inherit;
        }

    }


    @media screen and (min-width: 100em) {/* 1600px */
        background-color:$color-primary;
        margin-right: 8.33333333%;

        &:before,
        &:after {
            content: "";
        }

        &:after {
            width: calc(1440px / 24);
            left: 100%;
        }

        h2 {
            color: $color-white;
        }
    }
}
