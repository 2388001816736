@media screen and (min-width: 62em) { /* 992px */
    .sticky-nav-active {
        #header {
            height: 128px;
        }

        .section--header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background-color: $color-white;
            box-shadow: 0 2px 20px rgba(0,0,0,0.25);
        }
    }

}

.section--header {
    background: $color-white;
    padding: 0;
    padding-top: $spacing*2.5;
    padding-bottom: $spacing*2.5;

    @media screen and (min-width: 62em) { /* 992px */
        position: relative;
        z-index: 98;
        padding-top: $spacing*2.5;
        padding-bottom: $spacing*2;
        box-shadow: 0 0 20px rgba(0,0,0,0.15);
        transition: all 200ms ease-in-out;
    }
}

.header__search .field-element {
    margin-bottom: 0;
}

.header__logo a {
    display: block;
}

.logo__img {
    height: 92px;
    width: 236px;
}

.header__rhs {
    display: none;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (min-width: 35em) {
        display: flex;
    }
}

.header__topnav {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    margin-left: -16px;
    margin-right: -16px;
    background: $color-secondary;
    background: -moz-linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
    background: linear-gradient(90deg, rgba(35,64,143,1) 5%, rgba(0,169,229,1) 100%);
    color: $color-white;
    padding-bottom: 12px;
    padding-top: 12px;
    position: relative;
    z-index: 0;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: -24px;
        border-style: solid;
	    border-width: 48px 24px 0 24px;
        border-color: $color-primary transparent transparent transparent;
        z-index: -1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(100% - 1px);
        width: 300px;
        background-color: $color-secondary;
        z-index: -1;

        @media screen and (min-width: 90em) {/* 1400px */
            width: calc(50vw - 570px);
        }
    }

    .header__topnav__item {
        padding-left: 16px;
        padding-right: 16px;

        svg path {
            fill: $color-white;
        }

        a {
            display: block;
            text-decoration: none;
            color: $color-white;
        }

        &--contact {
            a {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }

            span,
            svg {
                margin-left: 4px;
                margin-right: 4px;
            }
        }

        &--insta,
        &--linkedin {
            svg {
                height: 1.5em;
                width: 1.5em;
            }
        }

        &--insta {
            padding-left: 8px;
        }

        &--linkedin {
            padding-right: 8px;
        }
    }
}
