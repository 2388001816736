.sitemap {
    list-style: none;
    padding: 0;
    margin: 0;

    &__item {
        a {
            text-decoration: none;
            color: $color-grey-07;
            display: block;
            padding: 9px 0;

            &:hover,
            &:focus {
                color: $color-primary;
            }
        }
    }

    /* - Depth 1 - */
    .depth1 {
        position: relative;
    }
    .depth1:last-child {
        border-bottom: none;
    }
    .depth1 > a {
        padding: 9px 0;
    }

    &__submenu {
        list-style-type: none;
    }

    /* - Depth 2 - */
    .depth2 {
        font-size: 1.4rem;
    }
    .depth2 a:before {
        content: "- ";
    }
    .depth2 a {
        padding: 3px 0 3px $spacing--large;
    }
}
