.breadcrumb {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    color: inherit;
}

.breadcrumb > li {
    display: inline-block;
}

.breadcrumb > li:before {
    content: "\00a0/\00a0";
}

.breadcrumb > li:first-child:before {
    content: none;
}

@media screen and (max-width: 47.9375em) { /* 767px */
    .breadcrumb {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.4;
    }
    .breadcrumb > li {
        display: none;
        margin-left: 0;
    }
    .breadcrumb > li:first-child,
    .breadcrumb > li:nth-last-child(2) {
        display: inline-block;
    }
    .breadcrumb > li:nth-last-child(2):before{
        background: none;
        content: '\003c\00a0';
        position: unset;
        font-weight: bold;
        position: relative;
        left: -1px;
        margin-left: 0px;
        top: 0;
    }
}

@media screen and (max-width: 61.25em) { /* 980px */
    /* code for the blog posts because the breadcrumbs are nonsensical */
    .blog-post-controller .breadcrumb > li {
        display: none;
        margin-left: 0;
    }
    .blog-post-controller .breadcrumb > li:nth-last-child(5) {
        display: inline-block;
    }
    .blog-post-controller .breadcrumb > li:nth-last-child(2) {
        display: none;
    }
    .blog-post-controller .breadcrumb > li:nth-last-child(5):before {
        background: none;
        content: '\003c\00a0';
        position: unset;
        font-weight: bold;
        position: relative;
        left: -1px;
        margin-left: 0px;
        top: 0;
    }
}


.inner-banner .breadcrumb {
    a {
        color: rgba(#fff, 0.8);

        &:hover,
        &:focus {
            color: rgba(#fff, 1);
        }
    }
}

.brand-banner .breadcrumb {
    @media only screen and (min-width: 62em) { /* 992px */
        a {
            color: rgba(#fff, 0.8);

            &:hover,
            &:focus {
                color: rgba(#fff, 1);
            }
        }
    }
}

