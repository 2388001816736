.section--article {
    background-image: url(../assets/img_blur-1.jpg);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 50vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.media-article {
    background-color: #fff;

    &__img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    &__inner {
        padding-left: 32px;
        padding-right: 32px;

        @media screen and (min-width: 22.5em) {/* 360px */
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    @media screen and (min-width: 100em) {
        margin-left: 5.6667%;
    }
}

.media-article__content {

    @include line-deco;

    & > *:last-child {
        margin-bottom: 0;
    }
}

@media screen and (min-width: 48em) {
    .media-article {
        position: relative;

        @supports(display: flex) {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        img {
            display: none;

            @supports(object-fit: cover) {
                display: block;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        &__inner {
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}
