

// Brand colours

$color-primary: 					#23408F; // NAVY

$color-secondary:                   #00A9E5; // ORANGE

$color-secondary--hover: 			darken($color-secondary, 40%);

$color-alternative:                 #EE4266; // optional - unused

$color-accent-01: 				    #F68120; // GREEN

$color-accent-02:                   #FFD23F; //optional - unused


// Neutral Colours

$color-white:						#ffffff;

$color-grey-01:                     #F0F0F0;

$color-grey-02:						#d4d4d4;

$color-grey-03:						#b8b8b8;

$color-grey-04:						#9c9c9c;

$color-grey-05:						#7f7f7f;

$color-grey-06:						#636363;

$color-grey-07:						#474747;

$color-grey-08:						#2b2b2b;

$color-grey-09:						#0f0f0f;

$color-black:						#000000;


// Utility Colours

$color-utility-positive:			#0E6F22;

$color-utility-positive-subtle:		#26883A;

$color-utility-alert:				#A72D00;

$color-utility-alert-subtle:		#CD4C1D;

$color-utility-warning:				#9E0E0B;

$color-utility-warning-subtle:		#C32C2A;

$color-utility-neutral:				#0192d0;

$color-utility-neutral-subtle:		#d3f2ff;

$color-utility-selection:           #FFC57D;
