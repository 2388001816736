
.card {
    background: $color-white;
    color: $color-grey-07;
    padding:0;

    @media screen and (min-width: 48em) { /* 768px */
        display:flex;
        flex-direction:column;
        height: 100%;
    }
}

.card:hover {
    box-shadow:0 0 2px 0 rgba(0, 0, 0, 0.33)
}

.card a:focus {
    text-decoration:underline
}

.card:focus-within {
    box-shadow:0 0 2px 0 rgba(0, 0, 0, 0.33)
}

.card:focus-within a:focus {
    text-decoration:none
}

.card__content {
    display:flex;
    flex-direction:column;
    flex-grow:1;
    order:1
}

.card__description--imageless {
    margin-top: 40px;
}

.card__description {
    margin-bottom: auto;
}

.card__description,
.card__hover,
.card__cta {
    padding-left: 15px;
    padding-right: 15px;
}

.card__cta {
    padding-bottom: 15px;
}

.card__heading {
    @include line-deco;

    a {
        text-decoration: none;
        color: inherit;
    }

    a:hover,
    .card__heading a:focus {
        color: $color-accent-01;
        opacity: 1;
    }

    &-imageless {
        padding-top: 25px;
    }
}

.card__img {
    position: relative;
    padding-top: 56.25%;
    background-color: $color-white;
    border: 1px solid $color-grey-02;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        max-width: 66%;
    }

    &-full {
        overflow: hidden;

        img {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: none;
            max-width: 100%;
            object-fit: cover;
        }
    }
}

.card__cta {
    order:2;
    margin-top: -20px;
}

.card__cta p {
    margin-bottom: 0;
}

.card__img {
    order: 0;
}

.card__description {
    order: 1;
    display: none;

    &-image {
        margin-top: 20px;
    }
}

@media screen and (min-width: 35em) { /* 560px */
    .card {
        border: 1px solid $color-grey-02;

        &__description {
            display: block;
        }

        &__img {
            border: none;
        }

        &__cta {
            margin-top: 15px;
            text-align: left;
        }
    }
}

@media screen and (min-width: 62em) { /* 992px */
    .card__description,
    .card__hover,
    .card__cta {
        padding-left: 25px;
        padding-right: 25px;
    }

    .card__cta {
        padding-bottom: 25px;
    }

    .card__description-image {
        margin-top: 30px;
    }
}

// Card wide / stacked
.card-portrait {
    .card__img {
        img {
            max-width: 66.66%;
            max-height: 66.66%;
        }

        @media screen and (min-width: 35em) { /* 560px */
            padding-top: 33.3%;
        }
    }
}
.card__list--stacked {
    .card {
        border: 1px solid $color-grey-02;

        @media screen and (min-width: 100em) {
            border: none;
        }
    }

    .card__img {
        border: none;

        @media screen and (min-width: 35em) { /* 560px */
            flex: 0 33.3%;
        }
    }

    @media screen and (min-width: 35em) { /* 560px */
        .card__content {
            flex-direction: row;
            align-items: center;
        }
    }

    .card__description {
        display: block;


        @media screen and (min-width: 35em) { /* 560px */
            flex: 0 1 66.6%;
            padding-left: 10px;
            padding-top: 25px;
            padding-bottom: 25px;
            display:flex;
            flex-direction:column;
            flex-grow:1;
            height: 100%;
            justify-content: center;
        }


        @media screen and (min-width: 62em) { /* 992px */
            padding-top: 25px;
            padding-bottom: 25px;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-left: 0;
        }

        &__link {
            margin-bottom: -20px;

            @media screen and (min-width: 35em) { /* 560px */
                margin-bottom: 0;
            }

            @media screen and (min-width: 75em) { /* 1200px */
                margin-top: auto;
            }
        }

    }
}


