
img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

figure {
    margin: 1em 0 2em;
}


.image--align-left {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 48em) { /* 768px */
        width: 46%;
        margin-right: 4%;
        margin-left: 0;
        float: left;
    }
}

.image--align-right {
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 48em) { /* 768px */
        width: 46%;
        margin-left: 4%;
        margin-right: 0;
        float: right;
    }
}


.image-caption {
    font-size: $font-regular*0.9;
    color: $color-grey-06;
}
