
.card__list--brands {
    .card__item--landscape {
        @media screen and (min-width: 48em) { /* 768px */
            @supports (display: grid) {
                grid-column: span 2;
            }
        }

        .card {

            .card__content {
                display: flex;
                flex-direction: row;

                .card__img {
                    flex: 0 0 auto;
                    flex-basis: 50%;
                    max-width: 50%;
                    padding-top: 28.125%;
                }

                .card__description {
                    margin-top: 32px;
                    margin-bottom: 24px;
                }

                .card__cta--inline {
                    margin-bottom: 0;
                }
            }
        }
    }
}
