.brand-banner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    color: inherit;
    background-color: #ffffff;

    @media screen and (min-width: 48em) { /* 768px */
        background: none;
    }

    @media only screen and (min-width: 62em) { /* 992px */
        min-height: calc(4vw + 92vw * 0.3333);
        background-color: #23408f;
        background-image: url(../assets/banner-background_v1.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top -100px right;

        color: #fff;
        align-items: flex-end;
        padding-bottom: 60px;
    }

    @media only screen and (min-width: 90em) { /* 1440px */
        /*
            half the screen width
            less
            half the container width
            (is the container outside gutter)
            plus
            33.33% of the container width (33.33% of 1140px) * 0.5625 (19x9 ratio)
        */
        min-height: calc(50vw - 570px + 214px);
    }

    @media screen and (min-width: 100em) { /* 1600px */
        min-height: calc(50vw - 720px + 270px);
    }

    @media screen and (min-width: 120em) { /* 1920px */
        min-height: calc(50vw - 720px + 180px);
    }

    @media screen and (min-width: 150em) { /* 2400px */
        background-position: top right;
        min-height: 570px;
    }

    &__img {
        min-height: 56.25vw;
        background-color: #fff;
        border: 1px solid #d7d7d7;
        z-index: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;

        @media only screen and (min-width: 62em) { /* 992px */
            flex: 0 1 auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: calc(4vw + 92vw * 0.6667 + 40px);
            min-height: unset;
        }

        @media only screen and (min-width: 90em) { /* 1440px */
            /*
                half the screen width
                less
                half the container width
                (is the container outside gutter)
                plus
                66.67% of the container width (66.67% of 1140px)
                + 60px of padding to space image away from banner content
            */
            right: calc(50vw - 570px + 820px);
        }

        img {
            max-width: 66.66%;
        }

    }

    .container {
        position: relative;
        z-index: 2;
    }

    &__content {
        h1 {
            margin-top: 0.5em;
            margin-bottom: 0.25em;
        }

        @media only screen and (min-width: 62em) { /* 992px */
            margin-left: 33.33%;
        }
    }
}

.brand-banner + .section {
    padding-top: 0;

    @media only screen and (min-width: 62em) { /* 992px */
        padding-top: 60px;
    }
}
