.card__list {
    padding:0;
    margin:0;
    list-style:none;
}


/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .card__list {
        @include clearfix;

        margin-left: -16px;
        margin-right: -16px;
        margin-top: -16px;
        margin-bottom: -16px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .card__item {
        flex: 1 1 auto;
        max-width: 100%;
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    @supports (display: grid) {
        .card__list {
            margin-top: 0;
            margin-bottom: 0;
        }

        .card__item {
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .card__list--brands .card__item {
        flex: 1 1 50%;
        max-width: calc(50% - 32px);

        @supports (display: grid) {
            max-width: 100%;
        }
    }
}

@supports (display: grid) {
    .card__list {
        display:grid;
        grid-gap:32px;
        grid-template-columns:repeat(auto-fill, minmax(260px, 1fr));

        .card__item {
            width: 100%;
        }

        @media screen and (min-width: 48em) { /* 768px */
            margin-left: 0;
            margin-right: 0;

            &:after,
            &:before {
                content: none;
            }

            .card__item {
                width: 100%;
            }
        }
    }

    .card__list--brands {
        grid-gap:15px;
        grid-template-columns: 1fr;

        @media screen and (min-width: 48em) { /* 768px */
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr auto;
            grid-gap:32px;
        }
    }

    .card__list--lg {
        grid-template-columns:repeat(auto-fill, minmax(260px, 1fr));

        @media screen and (min-width: 35em) { /* 560px */
            grid-template-columns:repeat(auto-fill, minmax(360px, 1fr));
        }
    }

    .card__item+.card__item {
        margin-top:0
    }

}

@media screen and (min-width: 48em) { /* 768px */
    .card__list--2,
    .card__list--4,
    .card__list--8 {
        .card__item {
            flex: 1 1 50%;
            max-width: calc(50% - 32px);
        }
    }

    .card__list--3,
    .card__list--5,
    .card__list--6,
    .card__list--7,
    .card__list--9 {
        .card__item {
            flex: 1 1 33%;
            max-width: calc(33% - 32px);
        }
    }

    @supports (display: grid) {
        .card__list--2,
        .card__list--4,
        .card__list--8 {
            grid-template-columns: 1fr 1fr;
            .card__item {
                width: 100%;
                max-width: 100%;
            }
        }

        .card__list--3,
        .card__list--5,
        .card__list--6,
        .card__list--7,
        .card__list--9 {
            grid-template-columns: 1fr 1fr 1fr;
            .card__item {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@media screen and (min-width: 75em) {
    .card__list--4,
    .card__list--8 {
        .card__item {
            flex: 1 1 25%;
            max-width: calc(25% - 32px);
        }
    }

    @supports (display: grid) {
        .card__list--4,
        .card__list--8 {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            .card__item {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}
