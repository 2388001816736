
/* ------------------------------------------------------
**** ATOMS
------------------------------------------------------ */

/* ---- Global Variables ---- */

@import "06-abstracts/colors";

@import "06-abstracts/fonts";

@import "06-abstracts/spacing";

@import "06-abstracts/breakpoints";

@import "06-abstracts/decorative-mixins";

@import "06-abstracts/animation";

@import "06-abstracts/agnositc-javascript-animations";



/* ---- Base HTML ---- */


@import "00-base/_base/viewport";

@import "00-base/_base/box-model-reset";

@import "00-base/_base/page";

@import "00-base/_base/font-face";

@import "00-base/_flexboxgrid-extensions/_flexboxgrid-extensions";

@import "00-base/images/img";

@import "00-base/container/container";

@import "00-base/wrap/wrap";

@import "00-base/section/background-colours";

@import "00-base/section/section";

@import "00-base/section/affiliated-entities";

@import "00-base/block/block";

@import "00-base/box/box";

@import "00-base/content-align/content-align";

@import "00-base/content-columns/content-columns";

@import "00-base/floats/floats";




/* ---- Text ---- */

@import "00-base/typography/headings";

@import "00-base/typography/paragraph";

@import "00-base/typography/text-colours";

@import "00-base/typography/selected-text";

@import "00-base/typography/inline-elements";

@import "00-base/typography/hr";

@import "00-base/typography/lists/lists";

@import "00-base/typography/lists/inline-list";



/* ---- Buttons and links ---- */



@import "00-base/buttons-and-links/text-link";

@import "00-base/buttons-and-links/button";





/* ---- Images and Icons ---- */


@import "00-base/icons/icons";




/* ---- Video ---- */


@import "00-base/video-embed";



/* ---- Tables ---- */


@import "00-base/tables/table";

@import "00-base/tables/content-table/content-table";

@import "00-base/tables/small-content-table/small-content-table";

@import "00-base/tables/responsive-table/responsive-table";






/* ---- Helpers ---- */


@import "00-base/helpers/clearfix";

@import "00-base/helpers/img-replace";

@import "00-base/helpers/hidden/hidden";

@import "00-base/helpers/vis-hidden/vis-hidden";

@import "00-base/helpers/invisible/invisible";

@import "00-base/helpers/_pseudo-element-content";



/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */

/* ---- Text ---- */

@import "01-components/text-blocks/blockquote";

@import "01-components/text-blocks/highlight";

@import "01-components/text-blocks/expando";

@import "01-components/text-blocks/text-columns";

@import "01-components/text-blocks/site-messages";

@import "01-components/text-blocks/tooltip";



/* ---- Lists ---- */


@import "01-components/lists/sidebar-linklist";

@import "01-components/lists/social-list";

@import "01-components/lists/children-pg-list/children-pg-list";

@import "01-components/lists/file-list";

@import "01-components/lists/block-list/block-list";



/* ---- Navigation ---- */


@import "01-components/navigation/breadcrumb";

@import "01-components/navigation/pagination";

@import "01-components/navigation/primary-nav/_primary-nav";

@import "01-components/navigation/related-links";

@import "01-components/navigation/article-links";

@import "01-components/navigation/sitemap";



/* ---- Plugins / third-party styles ---- */

@import "01-components/magnific-popup/magnific-popup";



/* ---- Slide Toggle ---- */

@import "01-components/slide-toggle/slide-toggle";



/* ---- Blocks ---- */


@import "01-components/date-card/date-card";

@import "01-components/tile/tile";

@import "01-components/hero-banner/hero-banner-01";

@import "01-components/hero-banner/inner-banner";

@import "01-components/hero-banner/brand-banner";

@import "01-components/image-galleries/image-gallery";

@import "01-components/media-objects/media-object";

@import "01-components/media-objects/media-article";

@import "01-components/media-objects/card";

@import "01-components/media-objects/card-landscape";

@import "01-components/image-slider/slick-slider/image-slider";

@import "01-components/image-slider/slick-slider/image-slider-theme";

@import "01-components/image-slider/sans-slick/image-slider-clean";

@import "01-components/img-list-bar/img-list-bar";

@import "01-components/testimonial/testimonial";

@import "01-components/call-to-action/call-to-action";

@import "01-components/enquiry-form/enquiry-form";

@import "01-components/introduction/introduction";





/* ---- Forms ---- */


@import "01-components/forms/form-base";

@import "01-components/forms/color-picker";

@import "01-components/forms/date-input";

@import "01-components/forms/date-picker";

@import "01-components/forms/date-time-input";

@import "01-components/forms/fieldsets";

@import "01-components/forms/money";

@import "01-components/forms/range";

@import "01-components/forms/select";

@import "01-components/forms/range";

@import "01-components/forms/text-field";

@import "01-components/forms/time-pickers";

@import "01-components/forms/total-selector";

@import "01-components/forms/upload";

@import "01-components/forms/submit-bar";

@import "01-components/forms/submit-button";

@import "01-components/forms/site-search";

@import "01-components/forms/captcha";

@import "01-components/forms/upload/upload--inline";


/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */

/* ---- Global ---- */

@import "02-layout/header/header";

@import "02-layout/footer/footer";

@import "02-layout/sidebar/sidebar";

@import "02-layout/lists/tile-list";

@import "02-layout/lists/top-heavy-list";

@import "02-layout/lists/media-object_list";

@import "02-layout/lists/card_list";


/* ------------------------------------------------------
**** MODULES
------------------------------------------------------ */

/* ------------------------------------------------------
**** A new world order. Craft.
------------------------------------------------------ */
/*
 * aka, you're permitted to put core sprout styles in here
 * as they won't be included in the usual sprout needs helper
 */
 @import "03-modules/blogposts/blogposts-core";



/*
 * NOTE: [modules:01]
 * DO NOT PUT core module css here
 * the following are for bespoke skin styles that are ADDITIONAL to core module css
 */

@import "03-modules/blogposts/blogposts";




/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */

@import "04-pages/home/home";

@import "04-pages/inner/inner";



/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */

@media print {
    * {
        background: transparent !important;
        color: #000 !important; /* Black prints faster: h5bp.com/s */
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /* Don't show links for images, or javascript/internal links */

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; /* h5bp.com/t */
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 0.5cm;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
