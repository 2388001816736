/* ---- Slide Toggle ---- */
.st--trigger {
	display: block;
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
}

.st--trigger:hover,
.st--trigger:focus {
    background-color: $color-primary;
    color: #fff;
}

.st--trigger:before,
.st--trigger:after {
	content: "\00a0";
	width: 12px;
	height: 3px;
	display: block;
	position: absolute;
	background-color: $color-grey-05;
	margin: 0 auto;
	right: 10px;
	top: 50%;
	-webkit-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}

.st--trigger:hover:before,
.st--trigger:focus:before,
.st--trigger:hover:after,
.st--trigger:focus:after {
	background-color: #fff;
}


.st--trigger:before {
	-webkit-transform: rotate(45deg) translateX(-5px);
	-ms-transform: rotate(45deg) translateX(-5px);
	transform: rotate(45deg) translateX(-5px);
}

.st--trigger:after {
	-webkit-transform: rotate(-45deg) translateX(5px);
	-ms-transform: rotate(-45deg) translateX(5px);
	transform: rotate(-45deg) translateX(5px);
}

.st--expanded {
	color: $color-primary;
}

.st--trigger.st--expanded:before,
.st--trigger.st--expanded:after {
	top: calc(50% - 6px);
	background-color: $color-primary;
}

.st--trigger.st--expanded:hover:before,
.st--trigger.st--expanded:hover:after,
.st--trigger.st--expanded:focus:before,
.st--trigger.st--expanded:focus:after {
	background-color: #fff;
}

.st--trigger.st--expanded:before {
	-webkit-transform: rotate(-45deg) translateX(-5px);
	-ms-transform: rotate(-45deg) translateX(-5px);
	transform: rotate(-45deg) translateX(-5px);
}

.st--trigger.st--expanded:after {
	-webkit-transform: rotate(45deg) translateX(5px);
	-ms-transform: rotate(45deg) translateX(5px);
	transform: rotate(45deg) translateX(5px);
}
