.sidebar-item > *:last-child {
    margin-bottom: 0;
}

.sidebar--cta {
    padding: 32px;
    margin-top: 40px;
}

@media screen and (min-width: 48em) {
    .sidebar {
        padding-right: 60px;

        @media only screen and (min-width: 100em) { /* 1600px */
            padding-right: 92px;
        }
    }
}


