:root {
    --section: 40px;
}

@media screen and (min-width: 48em) { /* 768px */
    :root {
        --section: 60px;
    }
}

.section {
    padding-top: var(--section);
    padding-bottom: var(--section);
}

.section-xsmall {
    padding-top: $font-regular;
    padding-bottom: $font-regular;
}

.section-small {
    padding-top: 25px;
    padding-bottom: 25px;
}

.section-large {
    padding-top: 60px;
    padding-bottom: 60px;
}

.section-t0 {
    padding-top: 0;
}

.section-b0 {
    padding-bottom: 0;
}


/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section {
        padding-top: var(--section);
        padding-bottom: var(--section);
    }

    .section-small {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .section-large {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    .section-t0 {
        padding-top: 0;
    }

    .section-b0 {
        padding-bottom: 0;
    }
}

.section-small-x {
    padding-left: 25px;
    padding-right: 25px;
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .section-small-x {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.section-small-x > *:last-child {
    margin-bottom: 0;
}
