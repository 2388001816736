

ul,
ol
dl, {
    margin: 0 0 1.6rem;
    padding: 0 0 0 2rem;
}

li ul,
li ol {
    margin: 1rem 0;
}

ol {
    list-style-type: none;
    counter-reset: item;
}

ol ul {
    list-style-type: disc;
}

ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol > li {
    margin: 0;
}

li ol > li:before {
    content: counters(item, ".") " ";
}

.linklist {
    a {
        color: inherit;
        opacity: 0.75;
        text-decoration: none;
    }

    a:hover,
    a:focus {
        color: inherit;
        opacity: 1;
        text-decoration: underline;
    }
}
