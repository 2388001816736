.section--testimonials {
    background-image: url(../assets/img_blur-2.jpg);
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 50vh;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .container {
        background-color: $color-white;
        padding-left: 40px;
        padding-right: 40px;

        @media screen and (min-width: 48em) { /* 768px */
            padding-left: 80px;
            padding-right: 80px;
        }

        @media screen and (min-width: 62em) { /* 992px */
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.testimonial {
    text-align: center;

    &__quote {
        @media screen and (min-width: 35em) { /* 560px */
            font-size: 2rem;
        }

        @media screen and (min-width: 48em) { /* 768px */
            font-size: 2.4rem;
        }
    }

    &__author {
        color: $color-grey-05;

        @media screen and (min-width: 48em) { /* 768px */
            font-size: 2rem;
        }
    }

    &__cta {
        text-align: center;
        margin-bottom: 0;
    }
}

.testimonial-single {
    background-image: none;
    background-color: $color-primary;

    .testimonial {
        text-align: left;

        &__title {
            font-size: 2.4rem;
            display: block;

            @media screen and (min-width: 35em) {
                font-size: 2.8rem;
            }
        }

        &__cta {
            text-align: left;
        }
    }
}
