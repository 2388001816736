* {
    margin: 0;
}

html,
body {
    background: #FFF;
    height: 100%;
}

html {
    font-size: 62.5%;
}

body {
    line-height: 1.5;
    font-size: $font-regular;
    color: $color-grey-07;
    font-family: $primary-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media screen and (min-width: 100em) { /* 1600px */
        font-size: 1.8rem;
    }
}
