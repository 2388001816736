
.section--brand-bar {
    overflow: hidden;
    font-size: 1.8rem;
    line-height: normal;
    font-weight: bold;
    color: $color-grey-05;

    &-slider {
        max-width: calc(88vw + 5px);
        margin-left: -5px;

        @media screen and (min-width: 48em) {
            max-width: 92vw;
        }
    }
}
.brand-bar__heading {
@media screen and (min-width: 62em) { /* 992px */
        margin-bottom: 0;
        font-size: 2.8rem;
        line-height: normal;

        span {
            display: block;
        }
    }

    @media screen and (min-width: 75em) { /* 1200px */
        font-size: 2.6rem;
    }

    @media screen and (min-width: 100em) {/* 1600px */
        font-size: 3.2rem;
    }
}

.img-list-bar {
    list-style-type: none;
    padding: 0;
    margin-left: 0;
    letter-spacing: 0;

    @include clearfix;

    @supports(display: grid) {
        display:grid;
        grid-gap: 10px;
        grid-template-columns:repeat(2, 1fr);


        @media screen and (min-width: 36em) {
            grid-gap: 16px;
            grid-template-columns:repeat(5, 1fr);
        }

        @media screen and (min-width: 48em) { /* 768px */

        }

        @media screen and (min-width: 62em) {
            grid-template-columns:repeat(4, 1fr);
        }

        &:before,
        &:after {
            content: none;
        }
    }

    &__item {
        display: block;
        float: left;
        height: 140px;
        width: 140px;
        padding: 10px 15px 10px 0px;

        @supports(display: grid) {
            float: none;
            height: 100%;
            width: 100%;
            padding: 0;
        }

        &__imgwrap {
            border: 1px solid $color-grey-02;
            position: relative;
            padding-top: 100%;
            background-color: $color-white;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: auto;
                max-width: 66%;
            }
        }
    }
}

/* js--brand-slider */
.img-list-bar.slick-slider {
    display: block;
    min-height: 88px;
}
.img-list-bar__item.slick-slide {
    margin-left: 5px;
    margin-right: 5px;
    float: left;
}

.slick-dotted.slick-slider.img-list-bar {
    margin-bottom: 0;

    .slick-dots {
        bottom: -25px;

        li button {
            background: $color-grey-05;
        }
    }
}

/*
js--fadein-list

.js .img-list-bar__item {
    animation: none;
    opacity: 0;
    transform: translateX(30px);
    transition: all 400ms ease-in-out;
}

.no-js .img-list-bar__item {
    opacity: 1;
    transform: translateX(0px);
}

.img-list-bar__item.is-showing,
.js .img-list-bar__item.is-showing {
    opacity: 1;
    transform: translateX(0px);
}

*/
