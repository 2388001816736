.columns-text {
    @media screen and (min-width: 62em) { /* 992px */
        columns: 2;
        column-gap: 40px;
    }

    @media screen and (min-width: 75em) { /* 1200px */
        column-gap: 64px;
    }

    p, li {
        break-inside: avoid;
    }
}
