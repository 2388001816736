// grid layout for posts
@media screen and (min-width: 48em) {
    .post-hub--grid {
        margin-left: -16px;
        margin-right: -16px;
    }

    .post-hub--grid .post-hub__item {
        width: calc(33.3% - 32px);
        margin-left: 16px;
        margin-right: 16px;
    }

    .post-hub--grid .post-hub__item__content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    @supports (display: grid) {
        .post-hub--grid {
            display: grid;
            grid-gap: 32px;
            grid-template-columns: repeat(3, 1fr);
            margin-left: 0;
            margin-right: 0;

            @media only screen and (min-width: 62em) {
                grid-gap: 40px;
            }
        }

        .post-hub--grid.-clearfix:before,
        .post-hub--grid.-clearfix:after {
            content:none
        }

        .post-hub--grid .post-hub__item {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }
}


.post-hub {
    &__body {
        h2 {
            font-size: 2rem;
            font-weight: 400;
            margin-bottom: 0;

            @media screen and (min-width: 100em) {
                font-size: 2.4rem;
            }

            a {
                color: inherit;
                text-decoration: none;
            }
        }

        p {
            color: #474747;
        }

        .post-list__date {
            color: #7f7f7f;
        }
    }

    &__footer {
        margin-top: auto;

        p {
            margin-bottom: 0;
        }
    }
}

/* single post */
.entry-date {
    color: #7f7f7f;
}

.inner-banner .entry-date {
    color: $color-white;
}

.post-listing .post-hub__item {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #d7d7d7;
}


