.hero-banner {
    background-color: $color-primary;
    background-image: url(../assets/banner-background_v2.svg);

    @media screen and (min-width: 120em) { /* 1920px */
        background-size: 100% auto;
    }

    &__item {
        position:relative;
        background-color: $color-primary;
        color: $color-white;
        max-width: 100vw;

        @media screen and (min-width: 48em) {
            padding-top: calc(40vw * 1.77778);
            background: none;
        }

        @media screen and (min-width: 75em) { /* 1200px */
            padding-top: 520px;
        }

        @media screen and (min-width: 100em) {
            padding-top: 720px;
        }
    }

    &__item--image-landscape {
        .hero-banner__text__inner {
            @media screen and (min-width: 48em) {
                background-color:rgba($color-primary, 0.75);
            }

            @media screen and (min-width: 100em) { /* 1600px */
                background-color: transparent;
            }
        }
    }

    @media screen and (min-width: 75em) {
        height: 520px;
        overflow: hidden;
    }

    @media screen and (min-width: 100em) {
        height: 720px;
    }

    &__picture {
        width: 100%;
        background: none;

        @media screen and (min-width: 48em) {
            width: auto;
            padding-top: 0;
            position: absolute;
            top: 50%;
            bottom: auto;
            left: auto;
            right: 4vw;
            transform: translateY(-50%);
            box-shadow: 0 12px 32px rgba(0,0,0,0.25);
        }

        @media screen and (min-width: 75em) {
            right: calc(50vw - 570px);
        }

        @media screen and (min-width: 100em) {
            right: calc(50vw - 720px);
        }
    }

    &__text {
        padding: 24px 0 40px;

        & h2,
        & h3 {
            color: #fff;
        }

        @media screen and (min-width: 48em) {
            padding-top: 20px;
            padding-bottom: 20px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            padding: 0;
        }

        &__inner {
            @media screen and (min-width: 48em) {
                position: absolute;
                bottom: auto;
                top: 50%;
                left: 0;
                color:#FFF;
                background-color: transparent;
                transform: translateY(-50%);
                padding: 30px;
                z-index: 2;
                width: 60vw;
                padding-left: 4vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @media screen and (min-width: 62em) {
                width: 50vw;
            }

            @media screen and (min-width: 75em) {
                // width: calc(50vw + (50vw - 570px));
                width: 66.66vw;
                padding-left: calc(50vw - 570px);
                padding-right: 50px;
            }

            @media screen and (min-width: 100em) {
                // width: calc(50vw + (50vw - 720px));
                width: 50vw;
                padding-left: calc(50vw - 720px);
                padding-right: 80px;
            }
        }

        .container {
            @media screen and (min-width: 48em) {
                height: 100%;
                max-width: 100%;
                width: 100%;
            }
        }

        &__description {
            @media screen and (min-width: 100em) { /* 1600px */
                font-size: 2rem;
            }
        }
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    &.slick-slider .slick-slide {
        height: auto;
    }

    .slick-prev,
    .slick-next {
        top: calc((56.25vw / 2) - 16px);
        transform: none;
    }

    .slick-dots {
        top: calc(100vw - 20px);
        bottom: auto;

        @media screen and (min-width: 48em) {
            top: calc((40vw * 1.77778) - 20px);
        }

        @media screen and (min-width: 75em) { /* 1200px */
            top: 480px;
        }

        @media screen and (min-width: 100em) {
            top: 680px;
        }
    }
}

.hero-banner__text__inner *:last-child {
    margin-bottom: 0;
}


