

$primary-font: 'Open Sans', Helvetica, Arial, sans-serif;

$primary-fw: 400;

$light-fw: 300;

$bold-fw: 700;

$accent-font: 'Open Sans', Helvetica, Arial, sans-serif;

$font-small: 1.2rem;

$font-regular: 1.5rem;
