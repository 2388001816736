.archive-links {
	margin-top: 20px;


	h3 {
		margin-bottom: 8px;
		margin-top: 16px;
	}

	> h3:first-child {
		margin-top: 0;
	}

	.linklist {
		margin-bottom: 0;

		li {
			padding-top: 0;
			padding-bottom: 0;
		}

		> li {
			font-size: inherit;
			margin-bottom: 4px;
			margin-top: 16px;
		}

		> li:first-child {
			margin-top: 0;
		}

		a {
			padding-top: 4px;
			padding-bottom: 4px;
			margin-bottom: 0;
			color: inherit;
			opacity: 0.66;
			text-decoration: none;
		}

		a:hover,
		a:focus {
			opacity: 1;
		}

		a:after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' preserveAspectRatio='none' viewBox='0 0 10 8'%3E%3Cpath d='M0 4.03h9.006M6.172 6.858l3.19-3.179m-3.19-2.477l3.19 3.184' style='stroke:%235F707E%3B' /%3E%3C/svg%3E");
		}

		a:hover:after,
		a:focus:after,
		a:active:after {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' preserveAspectRatio='none' viewBox='0 0 10 8'%3E%3Cpath d='M0 4.03h9.006M6.172 6.858l3.19-3.179m-3.19-2.477l3.19 3.184' style='stroke:%2301232F%3B' /%3E%3C/svg%3E");
		}

		a.active:after {
			content: "\2022";
			background: none;
			margin-left: 0;
		}

		ul {
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;
		}

		h4 {
			padding-left: 10px;
			margin-bottom: 0;
			color: $color-grey-05;
		}


		h4.st--expanded {
			color: $color-primary;
		}

		h4.st--trigger:hover,
		h4.st--trigger:focus {
			background-color: $color-primary;
			color: #fff;
		}

		> ul li a {
			padding-left: 20px;
			display: inline-block;
		}

		> ul {
			padding-top: 8px;
		}
	}
}
