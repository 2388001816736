.children-gallery-list {
    list-style-type: none;
    margin: 20px 0 10px;
    padding: 0;
}



@supports (display: grid) {
    .children-gallery-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: $spacing*3;
        justify-items: stretch;
    }
    .children-gallery-list-item {
        max-width: 100%;
    }

    /* BP Smaller */
    @media screen and (min-width: 38.125em) { /* 610px */
        .children-gallery-list {
            grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
        }
    }
}

.children-gallery-list__item {
    padding: 0;
}
.children-gallery-list__item a {
    border-top: 1px solid $color-grey-04;
    border-bottom: 1px solid $color-grey-04;
    display: block;
    padding: $spacing*2 $spacing*2 $spacing*2 0;
    color: inherit;
    text-decoration: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
}
.children-gallery-list__item a:hover,
.children-gallery-list__item a:focus,
.children-gallery-list__item a:active {
    padding-right: 0;
    padding-left: $spacing*2;
}
