/* Animation Classes */
.js .js--fadein {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
}

.animate-fade-in,
.js .animate-fade-in {
    -webkit-animation: fadeInUp 300ms forwards;
    animation: fadeInUp 300ms forwards;
}
