.tinymce-media-iframe,
.widget-Video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    clear: both;
}

.tinymce-media-iframe iframe,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.widget-Video iframe {
    aspect-ratio: 16/9;
    height: unset;
}

.tinymce-media-iframe {
    margin: 2rem 0;
}

iframe {
    border: 0;
}

.section--video__video-wrapper {
    margin-bottom: 30px;

    /* BP medium */
    @media screen and (min-width: 62em) { /* 992px */
        margin-bottom: 0;
    }

}
