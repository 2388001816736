@charset "UTF-8";
/* ------------------------------------------------------
**** ATOMS
------------------------------------------------------ */
/* ---- Global Variables ---- */
@media screen and (min-width: 22.5em) {
  /* 360px */
}
@media screen and (min-width: 30em) {
  /* 480px */
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
}
/* BP medium */
@media screen and (min-width: 62em) {
  /* 992px */
}
/* BP large */
@media screen and (min-width: 75em) {
  /* 1200px */
}
/* BP xlarge */
@media screen and (min-width: 90em) {
  /* 1400px */
}
/* BP xlarge */
@media screen and (min-width: 100em) {
  /* 1600px */
}
/* Fade in */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Fade in down */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}
/* Fade in up margin */
@-webkit-keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@keyframes fadeInUpMargin {
  from {
    opacity: 0;
    margin-bottom: -20px;
  }
  to {
    opacity: 1;
    margin-bottom: 0;
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.animate-fade {
  animation: fadeInDown 400ms ease-in-out;
}

.demo-animate .demo-animate__item {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.demo-animate:hover .demo-animate__item {
  clip: auto;
  height: auto;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 5px;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* Animation Classes */
.js .js--fadein {
  opacity: 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
}

.animate-fade-in,
.js .animate-fade-in {
  -webkit-animation: fadeInUp 300ms forwards;
  animation: fadeInUp 300ms forwards;
}

/* ---- Base HTML ---- */
@viewport {
  width: device-width;
  zoom: 1;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

* {
  margin: 0;
}

html,
body {
  background: #FFF;
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1.5;
  font-size: 1.5rem;
  color: #474747;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (min-width: 100em) {
  body {
    /* 1600px */
    font-size: 1.8rem;
  }
}

/* google font */
.row--vertical-gutters *[class*=col-xs],
.row--vertical-gutters *[class*=col-sm],
.row--vertical-gutters *[class*=col-md],
.row--vertical-gutters *[class*=col-lg] {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Non-padded col items */
.row-0 {
  margin-left: 0;
  margin-right: 0;
}
.row-0 > .col-xs,
.row-0 > .col-xs-1,
.row-0 > .col-xs-2,
.row-0 > .col-xs-3,
.row-0 > .col-xs-4,
.row-0 > .col-xs-5,
.row-0 > .col-xs-6,
.row-0 > .col-xs-7,
.row-0 > .col-xs-8,
.row-0 > .col-xs-9,
.row-0 > .col-xs-10,
.row-0 > .col-xs-11,
.row-0 > .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}

/* Revert vertical alignment */
@media screen and (min-width: 48em) {
  .normal-sm {
    align-items: normal;
  }
}
@media screen and (min-width: 62em) {
  /* 992px */
  .normal-md {
    align-items: normal;
  }

  .col-md-pr {
    padding-right: calc(4.166% + 1rem);
  }

  .row-0 > .col-md-pr {
    padding-right: 4.166%;
  }
}
/* BP large */
@media screen and (min-width: 75em) {
  /* 1200px */
  .row-0 > .col-lg-pr0 {
    padding-right: 0;
  }
}
.row-gap-lg {
  margin-left: -16px;
  margin-right: -16px;
}

.row-gap-lg > div {
  padding-left: 16px;
  padding-right: 16px;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 1em 0 2em;
}

.image--align-left {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  .image--align-left {
    /* 768px */
    width: 46%;
    margin-right: 4%;
    margin-left: 0;
    float: left;
  }
}

.image--align-right {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  .image--align-right {
    /* 768px */
    width: 46%;
    margin-left: 4%;
    margin-right: 0;
    float: right;
  }
}

.image-caption {
  font-size: 1.35rem;
  color: #636363;
}

.container {
  width: 88%;
  max-width: 1140px;
  margin: 0 auto;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .container {
    width: 92%;
  }
}
@media screen and (min-width: 100em) {
  /* 1600px */
  .container--lg {
    max-width: 1440px;
  }
}
#wrap {
  overflow-x: hidden;
}

.bg-white {
  background-color: #ffffff;
}

.bg-lightgrey {
  background-color: #F0F0F0;
}

.bg-primary {
  background-color: #23408F;
}

.bg-secondary {
  background-color: #00A9E5;
}

.bg-alternative {
  background-color: #EE4266;
}

.bg-accent-01 {
  background-color: #F68120;
}

.bg-accent-02 {
  background-color: #FFD23F;
}

.reverse-text {
  color: #ffffff;
}

.reverse-text a {
  color: inherit;
}

.reverse-text a:hover,
.reverse-text a:focus,
.reverse-text a:active {
  color: inherit;
  opacity: 0.75;
}

:root {
  --section: 40px;
}

@media screen and (min-width: 48em) {
  /* 768px */
  :root {
    --section: 60px;
  }
}
.section {
  padding-top: var(--section);
  padding-bottom: var(--section);
}

.section-xsmall {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.section-small {
  padding-top: 25px;
  padding-bottom: 25px;
}

.section-large {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-t0 {
  padding-top: 0;
}

.section-b0 {
  padding-bottom: 0;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .section {
    padding-top: var(--section);
    padding-bottom: var(--section);
  }

  .section-small {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .section-large {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section-t0 {
    padding-top: 0;
  }

  .section-b0 {
    padding-bottom: 0;
  }
}
.section-small-x {
  padding-left: 25px;
  padding-right: 25px;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .section-small-x {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.section-small-x > *:last-child {
  margin-bottom: 0;
}

.section--affiliated-entities {
  position: relative;
  background-color: #23408F;
}
.section--affiliated-entities h2 {
  color: #ffffff;
}
.section--affiliated-entities:before, .section--affiliated-entities:after {
  content: "";
  position: absolute;
  width: 50vw;
  top: 0;
  bottom: 0;
  background-color: #23408F;
}
.section--affiliated-entities:before {
  right: 100%;
}
.section--affiliated-entities:after {
  left: 100%;
}
@media screen and (min-width: 75em) {
  .section--affiliated-entities {
    /* 1200px */
    background-color: #ffffff;
  }
  .section--affiliated-entities:before, .section--affiliated-entities:after {
    content: none;
  }
  .section--affiliated-entities h2 {
    color: inherit;
  }
}
@media screen and (min-width: 100em) {
  .section--affiliated-entities {
    /* 1600px */
    background-color: #23408F;
    margin-right: 8.33333333%;
  }
  .section--affiliated-entities:before, .section--affiliated-entities:after {
    content: "";
  }
  .section--affiliated-entities:after {
    width: calc(1440px / 24);
    left: 100%;
  }
  .section--affiliated-entities h2 {
    color: #ffffff;
  }
}

.block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb {
  margin-bottom: 20px;
}

.block-large {
  margin-top: 20px;
  margin-bottom: 20px;
}

.block-mb-large {
  margin-bottom: 20px;
}

.block-fs {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .block-large {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .block-mb-large {
    margin-bottom: 25px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .block-large {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .block-mb-large {
    margin-bottom: 30px;
  }
}
.box {
  padding: 20px 15px;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .box {
    padding: 20px;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .box {
    padding: 30px;
  }
}
.box > *:last-child {
  margin-bottom: 0;
}

/* - Right - */
.col--right-align {
  text-align: right;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--right-align {
    text-align: right;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--right-align {
    text-align: right;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--right-align {
    text-align: right;
  }
}
/* - Left - */
.col--left-align {
  text-align: left;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--left-align {
    text-align: left;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--left-align {
    text-align: left;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--left-align {
    text-align: left;
  }
}
/* - center - */
.col--center-align {
  text-align: center;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .col-sm--center-align {
    text-align: center;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .col-md--center-align {
    text-align: center;
  }
}
/* BP large */
@media only screen and (min-width: 75em) {
  /* 1200px */
  .col-lg--center-align {
    text-align: center;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .content-cols-2--sm {
    columns: 2;
  }
}
.left,
.center,
.right {
  margin: 30px auto;
  display: block;
  clear: both;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .left {
    margin: 0 30px 30px 0;
    float: left;
  }

  .right {
    margin: 0 0 30px 30px;
    float: right;
  }
}
/* ---- Text ---- */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0 0 1.5rem 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: bold;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
  margin-top: 3rem;
}

h1, .h1 {
  font-size: 3.2rem;
  line-height: 1.2;
}

h2, .h2 {
  font-size: 2.8rem;
  line-height: 1.4;
  color: #23408F;
}

h3, .h3 {
  font-size: 2.2rem;
  margin-bottom: 0;
}

h4, .h4 {
  font-size: 1.8rem;
  margin-bottom: 0;
  color: #00A9E5;
}

h5, .h5 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

h6, .h6 {
  font-size: 1.5rem;
}

p,
ol,
ul,
dl,
address {
  margin: 0 0 1.5rem;
}

small {
  font-size: 1.28rem;
}

/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  h1, .h1 {
    font-size: 4rem;
  }

  h2, .h2 {
    font-size: 3.2rem;
  }

  h3, .h3 {
    font-size: 2.4rem;
    margin-bottom: 0.75rem;
  }

  h4, .h4 {
    font-size: 2rem;
  }

  h5, .h5 {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 100em) {
  /* 1600px */
  h1, .h1 {
    font-size: 4.4rem;
  }

  h2, .h2 {
    font-size: 4rem;
  }

  h3, .h3 {
    font-size: 3.2rem;
  }

  h4, .h4 {
    font-size: 2.4rem;
  }

  h5, .h5 {
    font-size: 2rem;
    margin-bottom: 0;
  }

  h6, .h6 {
    font-size: 1.8rem;
  }
}
.heading-large-light {
  font-size: 3rem;
  line-height: normal;
  font-weight: 300;
}
@media screen and (min-width: 35em) {
  .heading-large-light {
    /* 560px */
    font-size: 4.2rem;
    line-height: 1.2;
  }
}
@media screen and (min-width: 100em) {
  .heading-large-light {
    /* 1600px */
    font-size: 5rem;
  }
}

.heading-medium-light {
  font-size: 2.4rem;
  font-weight: 300;
}
@media screen and (min-width: 35em) {
  .heading-medium-light {
    /* 560px */
    font-size: 3.2rem;
    line-height: 4.8rem;
  }
}

.heading-light {
  font-weight: 300;
}

.heading-inline {
  display: inline;
  float: left;
  padding: 0;
}

.heading-center {
  text-align: center;
}

.spacer-b {
  margin-bottom: 32px;
}

.heading--subtitle {
  margin-bottom: 0;
}

.subtitle {
  color: #7f7f7f;
  font-weight: 700;
}

.mainbar h1, .mainbar .h1 {
  font-size: 4rem;
}
.mainbar h2, .mainbar .h2 {
  font-size: 3.2rem;
  font-weight: 300;
}
.mainbar h3, .mainbar .h3 {
  font-size: 2.4rem;
  color: #636363;
}
.mainbar h4, .mainbar .h4 {
  font-size: 2rem;
}
.mainbar h5, .mainbar .h5 {
  font-size: 1.8rem;
}

p,
address {
  margin: 0 0 1.6rem;
}

.txt-c-primary {
  color: #23408F;
}

.txt-c-secondary {
  color: #00A9E5;
}

.txt-c-alternative {
  color: #EE4266;
}

.txt-c-accent-01 {
  color: #F68120;
}

.txt-c-accent-02 {
  color: #FFD23F;
}

.txt-c-white {
  color: #ffffff;
}

.txt-c-grey-01 {
  color: #F0F0F0;
}

.txt-c-grey-02 {
  color: #d4d4d4;
}

.txt-c-grey-03 {
  color: #b8b8b8;
}

.txt-c-grey-04 {
  color: #9c9c9c;
}

.txt-c-grey-05 {
  color: #7f7f7f;
}

.txt-c-grey-06 {
  color: #636363;
}

.txt-c-grey-07 {
  color: #474747;
}

.txt-c-grey-08 {
  color: #474747;
}

.txt-c-grey-09 {
  color: #0f0f0f;
}

.txt-c-black {
  color: #000000;
}

/* ---- Text selection ---- */
::-moz-selection {
  color: #000;
  background: #EAFF00;
}

::selection {
  color: #000;
  background: #EAFF00;
}

/* ---- Text level elements ---- */
abbr[title] {
  border-bottom: 1px dotted #c6bfbf;
  cursor: help;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

ins {
  background-color: #f6f6f6;
  color: #473f3f;
  text-decoration: none;
}

mark {
  background-color: #f6f6f6;
  color: #473f3f;
  font-style: italic;
  font-weight: bold;
}

pre,
code,
kbd,
samp {
  font-family: "Monaco", "Courier New", monospace;
  color: #484040;
  background: #efefef;
  background: rgba(0, 0, 0, 0.07);
  padding: 0 2px;
  margin: 0 -2px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

small {
  font-size: 1.28rem;
}

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 2rem 0;
  padding: 0;
}

ul,
ol dl {
  margin: 0 0 1.6rem;
  padding: 0 0 0 2rem;
}

li ul,
li ol {
  margin: 1rem 0;
}

ol {
  list-style-type: none;
  counter-reset: item;
}

ol ul {
  list-style-type: disc;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, ".") " ";
}

.linklist a {
  color: inherit;
  opacity: 0.75;
  text-decoration: none;
}
.linklist a:hover,
.linklist a:focus {
  color: inherit;
  opacity: 1;
  text-decoration: underline;
}

ul.inline,
ol.inline {
  list-style-type: none;
  margin-left: 0;
}

ul.inline > li,
ol.inline > li {
  display: inline-block;
  padding-left: 12px;
  padding-right: 12px;
}

/* ---- Buttons and links ---- */
a {
  color: #F68120;
  word-break: break-word;
  transition: all 250ms ease-in-out;
}

a:hover,
a:active,
a:focus {
  color: #a95007;
}

/* Address `outline` inconsistency between Chrome and other browsers. */
a:focus {
  outline: thin dotted;
}

/* Improve readability when focused and also mouse hovered in all browsers. */
a:active,
a:hover {
  outline: 0;
}

/* Include file type and size in document links */
a.document::after {
  content: " (" attr(data-ext) " " attr(data-size) ")";
}

.link-subtle {
  color: #7f7f7f;
  text-decoration: none;
}

.link-subtle:hover,
.link-subtle:focus {
  color: #F68120;
}

.button {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #F68120;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  text-transform: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  transition: all 150ms ease-in-out;
  font-size: 1.5rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
@media screen and (min-width: 100em) {
  .button {
    /* 1600px */
    font-size: 1.8rem;
  }
}

.button:hover,
.button:focus,
.button:active {
  color: #ffffff;
  background-color: #00A9E5;
  transform: translateY(-2px);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(0, 0, 0, 0.2);
}

.reverse-text .button {
  color: #fff;
  background-color: #F68120;
}

.reverse-text .button:hover,
.reverse-text .button:focus,
.reverse-text .button:active {
  color: #fff;
}

.bg-secondary .button:hover,
.bg-secondary .button:focus,
.bg-secondary .button:active {
  color: #ffffff;
  background-color: #23408F;
}

/* - Button icons - */
.button__icon {
  display: inline-block;
  vertical-align: top;
  width: 16px;
  height: 16px;
}

.button__icon--right {
  margin-left: 4px;
  margin-right: -2px;
}

.button__icon--left {
  margin-right: 4px;
  margin-left: -2px;
}

.button__icon svg {
  width: 16px;
  height: 16px;
}

.button__icon svg path {
  fill: #ffffff;
  transition: fill 250ms ease-in-out;
}

.button__icon--primary svg path {
  fill: #23408F;
}

.button__icon--secondary svg path {
  fill: #00A9E5;
}

.button:hover .button__icon--light-purple svg path {
  fill: #ffffff;
}

/* - Button styles - */
.reverse-text .button:hover,
.reverse-text .button:focus,
.reverse-text .button:active {
  opacity: 1;
}

/* Alternative */
.button--alt {
  background-color: #23408F;
  color: #ffffff;
}

.button--alt:hover,
.button--alt:focus {
  background-color: #192e66;
  color: #ffffff;
}

/* Red Warning Button */
.button--warning {
  background-color: #9E0E0B;
  color: #ffffff;
}

.button--warning:hover,
.button--warning:focus {
  background-color: #6e0a08;
  color: #ffffff;
}

/* Grey */
.button--neutral {
  background-color: #d4d4d4;
  color: #474747;
}

.button--neutral:hover,
.button--neutral:focus {
  background-color: #b8b8b8;
  color: #474747;
}

.button--neutral svg path {
  fill: #2b2b2b;
}

/* Green */
.button--positive {
  background-color: #26883A;
  color: #ffffff;
}

.button--positive:hover,
.button--positive:focus {
  background-color: #0E6F22;
  color: #ffffff;
}

/* Block */
.button--block {
  display: block;
  width: 100%;
}

/* Disabled/unavailable button style */
.button[disabled],
.button--disabled,
.button--disabled:hover,
.button--disabled:focus,
.button--disabled:active {
  border: none;
  opacity: 0.4;
  cursor: default;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/* Firefox: Get rid of the inner focus border */
.button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/* Regular */
.button {
  padding: 12px 20px 12px;
}

/* Small */
.button-small,
.button--small {
  font-size: 1.4rem;
  padding: 8px 12px 8px;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .button-small,
.button--small {
    font-size: 1.5rem;
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  /* Large */
  .button-large,
.button--large {
    font-size: 2.4rem;
    padding: 16px 32px;
  }

  .button--large .button__icon {
    width: 24px;
    height: 24px;
  }

  .button--large .button__icon svg {
    width: 24px;
    height: 24px;
  }

  .button--large .button__icon--right {
    margin-left: 4px;
    margin-right: -8px;
  }

  .button--large .button__icon--left {
    margin-right: 4px;
    margin-left: -8px;
  }
}
.back-btn-anchor {
  position: relative;
}

.back-btn {
  position: absolute;
  top: 0;
  left: 0;
}

/* ---- Images and Icons ---- */
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon svg {
  width: 16px;
  height: 16px;
}

.icon--24px svg {
  width: 24px;
  height: 24px;
}

.icon--32px svg {
  width: 32px;
  height: 32px;
}

.icon--40px svg {
  width: 40px;
  height: 40px;
}

.icon--48px svg {
  width: 48px;
  height: 48px;
}

.icon--52px svg {
  width: 52px;
  height: 52px;
}

.icon--60px svg {
  width: 60px;
  height: 60px;
}

.icon--68px svg {
  width: 68px;
  height: 68px;
}

/* Icon system colours */
.icon--primary svg path {
  fill: #23408F;
}

.icon--secondary svg path {
  fill: #00A9E5;
}

.icon--alternative svg path {
  fill: #EE4266;
}

.icon--white svg path {
  fill: #ffffff;
}

.icon--accent-01 svg path {
  fill: #F68120;
}

.icon--accent-02 svg path {
  fill: #FFD23F;
}

/* ---- Video ---- */
.tinymce-media-iframe,
.widget-Video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  clear: both;
}

.tinymce-media-iframe iframe,
.widget-Video iframe,
.widget-Video object,
.widget-Video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.widget-Video iframe {
  aspect-ratio: 16/9;
  height: unset;
}

.tinymce-media-iframe {
  margin: 2rem 0;
}

iframe {
  border: 0;
}

.section--video__video-wrapper {
  margin-bottom: 30px;
  /* BP medium */
}
@media screen and (min-width: 62em) {
  .section--video__video-wrapper {
    /* 992px */
    margin-bottom: 0;
  }
}

/* ---- Tables ---- */
table {
  border-collapse: collapse;
  border: 1px solid #7f7f7f;
}

th,
td {
  padding: 5px 10px;
  border: 1px solid #7f7f7f;
}

th,
thead td {
  background: #d4d4d4;
}

.table--content-standard {
  width: 100%;
  margin: 2rem 0;
}

.table--content-standard caption {
  text-align: left;
  padding-bottom: 13px;
}

.table--content-standard th,
.table--content-standard thead td {
  font-weight: bold;
  background-color: #d4d4d4;
  font-size: 1.7rem;
}

.table--content-standard td {
  background-color: #ffffff;
}

.table--content-standard th,
.table--content-standard td {
  border: 1px solid #9c9c9c;
  padding: 12px 15px;
  text-align: left;
}

.table--content-small {
  font-size: 1.3rem;
}

.table--content-small th,
.table--content-small td {
  padding: 5px 10px;
}

.table--content-small th,
.table--content-small thead td {
  font-size: 1.4rem;
}

@media only screen and (max-width: 600px) {
  .table--responsive {
    font-size: 1.4rem;
  }

  .table--responsive,
.table--responsive tbody,
.table--responsive tr {
    display: block;
    border: none;
  }

  .table--responsive thead,
.table--responsive th {
    display: none;
  }

  .table--responsive tr {
    display: table;
    width: 100%;
  }

  .table--responsive tr.table--responsive__first-row {
    display: none;
  }

  .table--responsive td {
    display: table-row;
    padding: 0;
  }

  .table--responsive td[data-title]:before {
    content: attr(data-title);
    font-weight: bold;
    background-color: #F0F0F0;
    border-right: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title] {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
  }

  .table--responsive tbody tr {
    margin-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .table--responsive td[data-title]:before,
.table--responsive__content {
    padding: 5px 15px;
    display: table-cell;
  }
}
/* ---- Helpers ---- */
.clear {
  height: 0;
  clear: both;
  display: block;
}

.-clearfix:before, .-clearfix:after {
  content: " ";
  display: table;
}
.-clearfix:after {
  clear: both;
}

.-ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
}

.-ir:before {
  content: "";
  display: block;
  width: 0;
  height: 150%;
}

.-hidden {
  display: none !important;
  visibility: hidden;
}

.-vis-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.-vis-hidden.focusable:active,
.-vis-hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.vis-show {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

@media screen and (min-width: 35em) {
  /* 560px */
  .vis-show--xs {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
.-invisible {
  visibility: hidden;
}

/* Angle Brackets */
.-r-arrow-after:after {
  content: " >";
}

.-r-arrow-before:before {
  content: "> ";
}

.-l-arrow-after:after {
  content: " <";
}

.-l-arrow-before:before {
  content: "< ";
}

/* Ellipsis */
.ellipsis-2:after {
  content: "..";
}

.ellipsis-3:after {
  content: "...";
}

/* ------------------------------------------------------
**** MOLECULES
------------------------------------------------------ */
/* ---- Text ---- */
blockquote {
  margin: 1.5rem 0;
  padding: 20px 25px 20px;
  background: #F0F0F0;
  font-style: italic;
  position: relative;
  clear: both;
}

blockquote *:first-child {
  margin-top: 0;
}

blockquote *:last-child {
  margin-bottom: 0;
}

blockquote:before,
blockquote:after {
  position: absolute;
  font-size: 13rem;
  display: block;
  height: 25px;
  width: 50px;
  color: #00A9E5;
  font-style: italic;
  line-height: 10px;
  font-family: "Big Caslon", "Book Antiqua", "Palatino Linotype", Georgia, serif;
}

blockquote:before {
  content: "“";
  top: 38px;
  left: -8px;
}

blockquote:after {
  content: "”";
  bottom: -36px;
  right: 18px;
}

@media only screen and (min-width: 62em) {
  /* 992px */
  .blockquote--left {
    float: left;
    width: 45%;
    margin-left: 0px;
    margin-right: 20px;
    clear: right;
  }

  .blockquote--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    margin-right: 0px;
    clear: left;
  }
}
.highlight,
.highlight--right,
.highlight--left {
  margin: 0 0 1.5rem;
  padding: 30px;
  background: #f6f6f6;
  clear: both;
}

/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .highlight--left {
    float: left;
    width: 45%;
    margin-right: 20px;
    clear: right;
  }

  .highlight--right {
    float: right;
    width: 45%;
    margin-left: 20px;
    clear: left;
  }
}
.highlight *:last-child,
.highlight--right *:last-child,
.highlight--left *:last-child {
  margin-bottom: 0;
}

.js .expando {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.js .expando p:last-of-type {
  margin-bottom: 0;
}

/* - Open button - */
.expando-open {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 6px 0 3px 40px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #F68120;
  position: relative;
  cursor: pointer;
  display: block;
  margin: 0 0 12px;
  font-size: inherit;
  line-height: inherit;
  transition: color 250ms ease-in-out;
}

.expando-open__icon {
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #F0F0F0;
  width: 26px;
  height: 26px;
  transform: translate(0, -50%);
}

.expando-open__icon:before,
.expando-open__icon:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #636363;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 250ms ease-in-out;
}

.expando-open__icon:after {
  transform: rotate(90deg);
}

/* Hover */
.expando-open:hover,
.expando-open:focus {
  color: #da6809;
  text-decoration: underline;
}

.expando-open:hover .expando-open__icon {
  background-color: #d4d4d4;
}

.expando-open:hover .expando-open__icon:before,
.expando-open:hover .expando-open__icon:after {
  background-color: #0f0f0f;
}

/* - Close button - */
.expando__close {
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: none;
  text-indent: -9999px;
  padding: 0;
  height: 26px;
  width: 26px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.expando__close:before,
.expando__close:after {
  content: " ";
  width: 14px;
  height: 2px;
  background-color: #636363;
  display: block;
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: transform 200ms ease;
}

.expando__close:before {
  transform: rotate(-45deg);
}

.expando__close:after {
  transform: rotate(45deg);
}

/* Hover */
.expando__close:hover:before,
.expando__close:hover:after {
  background-color: #0f0f0f;
}

/* - Expanded - */
.expando.expando--expanded {
  clip: auto;
  height: auto;
  margin: 20px 0 30px;
  overflow: visible;
  position: relative;
  width: auto;
  padding: 25px 45px;
  background-color: #F0F0F0;
  animation: fadeInDown 200ms ease;
  animation-fill-mode: forwards;
}

.expando-open--expanded .expando-open__icon:before {
  transform: rotate(-45deg);
}

.expando-open--expanded .expando-open__icon:after {
  transform: rotate(45deg);
}

@media screen and (min-width: 62em) {
  .columns-text {
    /* 992px */
    columns: 2;
    column-gap: 40px;
  }
}
@media screen and (min-width: 75em) {
  .columns-text {
    /* 1200px */
    column-gap: 64px;
  }
}
.columns-text p, .columns-text li {
  break-inside: avoid;
}

ul.messages {
  list-style: none;
  margin: 16px 0 25px;
  padding: 0;
}

ul.messages li {
  margin: 0 0 15px;
  padding: 10px 20px;
  vertical-align: bottom;
  text-align: left;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2em;
}

ul.messages .error {
  background: #C32C2A;
  border-left: 4px solid #9E0E0B;
  color: #FFF !important;
}

ul.messages .confirm {
  color: #FFF !important;
  background: #26883A;
  border-left: 4px solid #0E6F22;
}

ul.messages .warning {
  color: #FFF !important;
  background: #CD4C1D;
  border-left: 4px solid #A72D00;
}

ul.messages a {
  color: #FFF;
}

/* ---- Tooltips ---- */
.tooltip {
  position: relative;
}

.tooltip__title {
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: all 100ms ease-in-out;
  border-bottom: 1px dotted #7f7f7f;
}

.tooltip__title a {
  color: inherit;
}

.tooltip__title a:not(.button):hover,
.tooltip__title a:not(.button):focus {
  color: #00A9E5;
}

.tooltip:hover .tooltip__title {
  opacity: 0.7;
}

.tooltip__content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #404041;
  max-width: 270px;
  min-width: 120px;
  padding: 10px 10px;
  font-size: 1.1rem;
  border-radius: 4px;
  margin-top: 7px;
  text-align: center;
  pointer-events: none;
  color: #FFF;
  z-index: 98;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  white-space: normal;
  text-transform: none;
}

.tooltip__content small {
  font-size: 1.1rem;
}

.tooltip__content:before {
  content: " ";
  position: absolute;
  bottom: 100%;
  left: 14px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #404041 transparent;
}

/* Center align */
.tooltip--center .tooltip__content {
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltip--center .tooltip__content:before {
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* Right align */
.tooltip--right .tooltip__content {
  right: 0;
  left: auto;
}

.tooltip--right .tooltip__content:before {
  right: 14px;
  left: auto;
}

/* Up */
.tooltip--up .tooltip__content {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 7px;
}

.tooltip--up .tooltip__content:before {
  border-width: 5px 5px 0 5px;
  border-color: #404041 transparent transparent transparent;
  top: 100%;
}

/* Tooltip animations for hover state */
/* Fade in */
.tooltip:hover .tooltip__content,
.tooltip__title:hover + .tooltip__content {
  display: block;
  -webkit-animation: fadeIn 200ms ease;
  animation: fadeIn 200ms ease;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* Transition up */
.tooltip--transition-up .tooltip__content {
  -webkit-animation-name: fadeInUpMargin;
  animation-name: fadeInUpMargin;
}

/* Info icon */
.tooltip__icon {
  border-radius: 10px;
  background: #dcd9d1;
  color: #FFF;
  width: 20px;
  height: 20px;
  display: none;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 0;
}

.tooltip--icon .tooltip__icon {
  display: inline-block;
}

.tooltip__icon svg {
  width: 16px;
  height: 16px;
  padding: 2px;
}

.tooltip--icon .tooltip__title {
  border-bottom: none;
  margin-right: 7px;
}

.tooltip--icon .tooltip__content {
  left: -8px;
  right: auto;
}

.tooltip.tooltip--icon.tooltip--center .tooltip__content {
  right: auto;
  left: 50%;
}

.tooltip--icon.tooltip--right .tooltip__content {
  right: -8px;
  left: auto;
}

/* Disabled */
.tooltip--disabled .tooltip__content {
  display: none;
}

/* BP Large */
@media only screen and (min-width: 80em) {
  /* 1280px */
  .tooltip__content {
    max-width: 400px;
    min-width: 180px;
    font-size: 1.4rem;
  }
}
/* ---- Lists ---- */
.sidebar-linklist {
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}

.sidebar-linklist__item__button {
  display: block;
  background: none;
  border: none;
  padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
  content: ">";
  position: absolute;
  left: 0;
  color: #000;
  top: calc(10px + 0.1em);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
  left: 0.5em;
}

.sidebar-linklist__item--expanded .sidebar-linklist__item__button:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item__sublist__item {
  list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
  content: "– ";
  color: #000;
}

.sidebar-linklist__item__sublist__item__link {
  display: block;
  text-decoration: none;
  padding: 0.25em 0.25em 0.25em 1.5em;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
  background: rgba(0, 0, 0, 0.05);
}

.social-list {
  list-style: none;
  padding: 0;
  margin: 0px;
  margin-left: 4px;
  margin-right: 4px;
  height: 24px;
}

.social-list__item {
  display: inline-block;
}

.social-list__item a {
  color: inherit;
  display: block;
}

.children-gallery-list {
  list-style-type: none;
  margin: 20px 0 10px;
  padding: 0;
}

@supports (display: grid) {
  .children-gallery-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 24px;
    justify-items: stretch;
  }

  .children-gallery-list-item {
    max-width: 100%;
  }

  /* BP Smaller */
  @media screen and (min-width: 38.125em) {
    /* 610px */
    .children-gallery-list {
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    }
  }
}
.children-gallery-list__item {
  padding: 0;
}

.children-gallery-list__item a {
  border-top: 1px solid #9c9c9c;
  border-bottom: 1px solid #9c9c9c;
  display: block;
  padding: 16px 16px 16px 0;
  color: inherit;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.children-gallery-list__item a:hover,
.children-gallery-list__item a:focus,
.children-gallery-list__item a:active {
  padding-right: 0;
  padding-left: 16px;
}

.widget-FileList {
  background: #d4d4d4;
  padding: 20px;
  margin-bottom: 16px;
}

.widget-FileList ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget-FileList li {
  margin-bottom: 8px;
}

.widget-FileList li:last-child {
  margin-bottom: 0;
}

.widget-FileList .document {
  min-height: 24px;
  padding-left: 32px;
  background: url(../../images/icon-system/icon_document.svg) no-repeat;
}

.widget-FileList .document-pdf {
  background-image: url(../../images/icon-system/icon_pdf.svg);
}

.widget-FileList .document-png,
.widget-FileList .document-gif,
.widget-FileList .document-jpg {
  background-image: url(../../images/icon-system/icon_image.svg);
}

.widget-FileList .document-rtf,
.widget-FileList .document-txt,
.widget-FileList .document-doc,
.widget-FileList .document-docx {
  background-image: url(../../images/icon-system/icon_word-doc.svg);
}

.widget-FileList .document-csv,
.widget-FileList .document-xls,
.widget-FileList .document-xlsx {
  background-image: url(../../images/icon-system/icon_spreadsheet.svg);
}

.widget-FileList .document-mp3,
.widget-FileList .document-aac,
.widget-FileList .document-oga {
  background-image: url(../../images/icon-system/icon_sound.svg);
}

.widget-FileList .document-mp4,
.widget-FileList .document-mpeg,
.widget-FileList .document-mpg,
.widget-FileList .document-webm,
.widget-FileList .document-ogv,
.widget-FileList .document-aac,
.widget-FileList .document-avi,
.widget-FileList .document-mov,
.widget-FileList .document-wmv {
  background-image: url(../../images/icon-system/icon_video.svg);
}

.widget-FileList a {
  display: inline-block;
  text-decoration: none;
  color: #23408F;
  padding-bottom: 1px;
  border-bottom: 1px solid rgba(35, 64, 143, 0);
  transition: all 200ms ease-in-out;
}

.widget-FileList a:hover,
.widget-FileList a:focus,
.widget-FileList a:active {
  text-decoration: none;
  border-bottom: 1px solid #23408f;
}

@media screen and (min-width: 23.75em) {
  /* 380px */
  .widget-FileList {
    padding: 30px;
  }
}
.block-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.block-list li {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.block-list a {
  display: block;
  text-decoration: none;
  padding: 7px 0;
}

/* ---- Navigation ---- */
.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 1.4rem;
  color: inherit;
}

.breadcrumb > li {
  display: inline-block;
}

.breadcrumb > li:before {
  content: " / ";
}

.breadcrumb > li:first-child:before {
  content: none;
}

@media screen and (max-width: 47.9375em) {
  /* 767px */
  .breadcrumb {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.4;
  }

  .breadcrumb > li {
    display: none;
    margin-left: 0;
  }

  .breadcrumb > li:first-child,
.breadcrumb > li:nth-last-child(2) {
    display: inline-block;
  }

  .breadcrumb > li:nth-last-child(2):before {
    background: none;
    content: "< ";
    position: unset;
    font-weight: bold;
    position: relative;
    left: -1px;
    margin-left: 0px;
    top: 0;
  }
}
@media screen and (max-width: 61.25em) {
  /* 980px */
  /* code for the blog posts because the breadcrumbs are nonsensical */
  .blog-post-controller .breadcrumb > li {
    display: none;
    margin-left: 0;
  }

  .blog-post-controller .breadcrumb > li:nth-last-child(5) {
    display: inline-block;
  }

  .blog-post-controller .breadcrumb > li:nth-last-child(2) {
    display: none;
  }

  .blog-post-controller .breadcrumb > li:nth-last-child(5):before {
    background: none;
    content: "< ";
    position: unset;
    font-weight: bold;
    position: relative;
    left: -1px;
    margin-left: 0px;
    top: 0;
  }
}
.inner-banner .breadcrumb a {
  color: rgba(255, 255, 255, 0.8);
}
.inner-banner .breadcrumb a:hover, .inner-banner .breadcrumb a:focus {
  color: white;
}

@media only screen and (min-width: 62em) {
  .brand-banner .breadcrumb {
    /* 992px */
  }
  .brand-banner .breadcrumb a {
    color: rgba(255, 255, 255, 0.8);
  }
  .brand-banner .breadcrumb a:hover, .brand-banner .breadcrumb a:focus {
    color: white;
  }
}

.search-paginate {
  text-align: center;
  padding: 1.5rem;
  background: #b8b8b8;
}

.search-paginate .page {
  display: inline-block;
  background: #F68120;
  color: #fff;
  padding: 0.5rem 1.25rem;
  text-decoration: none;
  text-align: center;
  margin: 0 0.25rem;
}

.search-paginate .page.on {
  background: #a95007;
}

.search-paginate .page-next {
  margin-left: 1.5rem;
}

.search-paginate .page-prev {
  margin-right: 1.5rem;
}

@supports (display: flex) {
  .search-paginate {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.section--nav {
  background: #ffffff;
  padding: 0;
}

/*
* frankenMenu v1.0 // Karmabunny Web Design // built by Luke Underwood
*/
/** Desktop and Mobile styling
===================================*/
#frankenmenu-list,
#frankenmenu-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}

#frankenmenu-list a {
  text-decoration: none;
  display: block;
  font-size: 1.5rem;
}

/** Desktop menu only
===================================*/
@media screen and (min-width: 992px) {
  #frankenmenu {
    z-index: 1000;
  }

  #frankenmenu-list {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
  }

  #mobile-header {
    display: none;
  }

  /* ---- All level menu items ---- */
  #frankenmenu-list .menu-item {
    position: relative;
  }

  #frankenmenu-list .sub-menu {
    display: none;
    min-width: 200px;
    position: absolute;
    -webkit-box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.25);
    z-index: 1;
    min-width: 250px;
    max-width: 350px;
    text-align: left;
    top: 0;
    left: 100%;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1 {
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
  }

  #frankenmenu-list > .menu-item-depth1 {
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    display: block;
    color: #474747;
    padding: 0;
    font-weight: 400;
    position: relative;
  }

  #frankenmenu-list .menu-item-depth1 > a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    right: 50%;
    height: 2px;
    background-color: #23408F;
    transition: all 200ms ease-in-out;
  }

  /* Hover */
  #frankenmenu-list .menu-item-depth1 > a:hover,
#frankenmenu-list .menu-item-depth1 > a:focus,
#frankenmenu-list .menu-item-depth1.frankenhover > a,
#frankenmenu-list .menu-item-depth1.frankenhold > a {
    color: #23408F;
  }

  #frankenmenu-list .menu-item-depth1 > a:hover:before,
#frankenmenu-list .menu-item-depth1 > a:focus:before,
#frankenmenu-list .menu-item-depth1.frankenhover > a:before,
#frankenmenu-list .menu-item-depth1.frankenhold > a:before {
    left: 0;
    right: 0;
  }

  /* Current item */
  #frankenmenu-list .menu-item-depth1.menu-current-item > a {
    color: #F68120;
  }

  #frankenmenu-list .menu-item-depth1.menu-current-item > a:before {
    background-color: #F68120;
    left: 0;
    right: 0;
  }

  /* Ancestor item */
  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    background-color: #009ed6;
    color: #FFF;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu a {
    border-top: 1px solid #F0F0F0;
    padding: 10px 18px 12px;
    color: inherit;
    font-size: 1.5rem;
    line-height: 1.8rem;
    background-color: #ffffff;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
    font-weight: 400;
  }

  #frankenmenu-list .sub-menu .menu-item-depth1 > a {
    font-size: 1.4rem;
  }

  #frankenmenu-list .sub-menu .menu-item-depth1 > a:before {
    content: none;
  }

  #frankenmenu-list .sub-menu .menu-item:first-child a {
    border-top: none;
  }

  #frankenmenu-list .sub-menu .menu-item > a:hover,
#frankenmenu-list .sub-menu .menu-item > a:focus,
#frankenmenu-list .sub-menu .menu-item.frankenhover > a,
#frankenmenu-list .sub-menu .menu-item.frankenhold > a {
    background: #23408F;
    color: #ffffff;
  }

  #frankenmenu-list .frankenhold > .sub-menu {
    display: block !important;
    opacity: 1 !important;
  }

  /* ---- First level submenus ---- */
  #frankenmenu-list .sub-menu-depth1 {
    top: calc(100% + 16px);
    left: auto;
  }

  /* ---- Right aligned submenus ---- */
  #frankenmenu-list .sub-menu.submenu-right-align,
#frankenmenu-list .sub-menu.submenu-right-align .sub-menu {
    left: auto;
    right: 100%;
  }

  #frankenmenu-list .sub-menu-depth1.submenu-right-align {
    left: auto;
    right: 0;
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-list .menu-item-subnav {
    display: none;
  }

  #frankenmenu-subnav {
    float: right;
  }

  #frankenmenu-subnav-list,
#frankenmenu-subnav-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #frankenmenu-subnav-list .menu-item-depth1 {
    display: inline-block;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a {
    text-decoration: none;
    padding: 5px 5px;
    display: block;
    color: inherit;
  }

  #frankenmenu-subnav-list .menu-item-depth1 > a:hover {
    text-decoration: underline;
  }

  #frankenmenu-subnav-list .sub-menu {
    display: none;
  }

  /* ---- Moved elements ---- */
  .frankenmoved {
    display: none;
  }

  /* ---- Megamenu ---- */
  #frankenmenu-list .mega-menu {
    left: 0;
    right: 0;
    background-color: #FFF;
    text-align: left;
    top: 100%;
    position: absolute;
    border-bottom: 4px solid #D8D8D8;
    z-index: 51;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
  }

  /* Remove relative positioning for mega menu */
  .frankenmenu-mega #frankenmenu-list .menu-item {
    position: static;
  }

  #frankenmenu-list .menu-item.frankenhover .mega-menu,
#frankenmenu-list .menu-item.frankenhold .mega-menu {
    padding: 40px 0;
    width: auto;
    height: auto;
    clip: auto;
    overflow: visible;
    margin: auto;
  }

  #frankenmenu-list .menu-item.frankenhold .mega-menu {
    display: block !important;
    height: auto !important;
  }

  /* Megamenu preview */
  #frankenmenu-list .mega-menu-preview {
    float: left;
    color: #FFF;
    padding: 18px 20px;
    background-color: #6F6F6F;
    width: 22%;
    margin-right: 3%;
  }

  #frankenmenu-list .mega-menu-preview-image {
    height: 120px;
    background: url(../images/mega-menu-image-fallback.jpg) no-repeat center center;
    background-size: cover;
  }

  #frankenmenu-list .mega-menu-preview-text {
    margin-bottom: 0;
    line-height: 1.2;
  }

  /* Megamenu columns */
  #frankenmenu-list .mega-menu .mega-menu-columns {
    float: left;
    width: 75%;
  }

  #frankenmenu-list .mega-menu-column {
    width: 31.33333333%;
    float: left;
    margin-right: 3%;
  }

  #frankenmenu-list .mega-menu-column:last-child {
    margin-right: 0;
  }

  /* Megamenu submenus */
  #frankenmenu-list .mega-menu-submenu .menu-item {
    border-top: 1px solid #D8D8D8;
  }

  #frankenmenu-list .mega-menu-submenu .menu-item:last-child {
    border-bottom: 1px solid #D8D8D8;
  }

  #frankenmenu-list .mega-menu-submenu a {
    color: #000;
    padding: 5px 0;
  }

  /* navigation items on mobile */
  #frankenmenu-list .nav__mobile-item {
    display: none;
  }
}
@media screen and (min-width: 75em) {
  /* 1200px */
  #frankenmenu-list {
    margin-left: -12px;
    margin-right: -12px;
  }

  #frankenmenu-list > .menu-item-depth1 {
    margin-left: 12px;
    margin-right: 12px;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    letter-spacing: 1px;
  }

  #frankenmenu-list .sub-menu .menu-item-depth1 > a {
    letter-spacing: 0;
  }
}
/** Mobile menu only
===================================*/
@media all and (max-width: 991px) {
  /* Prevents font-boosting of menu items on Android */
  #frankenmenu * {
    max-height: 999999px;
  }

  #wrap {
    margin-top: 40px;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #FFF;
  }

  #frankenmenu {
    top: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    background-color: #ffffff;
    border-left: 1px solid #F0F0F0;
    width: 70%;
    z-index: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding-top: 40px;
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0);
  }

  #frankenmenu .container {
    position: relative;
    padding: 0;
    max-width: none;
    width: 100%;
    margin: 0;
    opacity: 0;
  }

  /* ---- Mobile header ---- */
  #mobile-header {
    background: #00A9E5;
    background: -moz-linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
    background: -webkit-linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
    background: linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  #mobile-menu-button {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
    padding: 0;
    text-indent: -9999px;
    width: 40px;
    height: 36px;
    margin-top: 2px;
    position: relative;
    background: none;
  }

  .mobile-menu-button-top,
.mobile-menu-button-middle,
.mobile-menu-button-bottom {
    height: 3px;
    background-color: #fff;
    left: 6px;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 400ms;
    animation-duration: 400ms;
    position: absolute;
    display: block;
    right: 6px;
  }

  .mobile-menu-button-top {
    top: 8px;
  }

  .mobile-menu-button-middle {
    top: 17px;
  }

  .mobile-menu-button-bottom {
    top: 26px;
  }

  /* Animations active */
  .frankenmenu-mob-menu-animations .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopClose;
    animation-name: mobileMenuBtnTopClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleClose;
    animation-name: mobileMenuBtnMiddleClose;
  }

  .frankenmenu-mob-menu-animations .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomClose;
    animation-name: mobileMenuBtnBottomClose;
  }

  /* Close button */
  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-top {
    -webkit-animation-name: mobileMenuBtnTopOpen;
    animation-name: mobileMenuBtnTopOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-middle {
    -webkit-animation-name: mobileMenuBtnMiddleOpen;
    animation-name: mobileMenuBtnMiddleOpen;
  }

  .frankenmenu-mob-menu-visible #mobile-menu-button .mobile-menu-button-bottom {
    -webkit-animation-name: mobileMenuBtnBottomOpen;
    animation-name: mobileMenuBtnBottomOpen;
  }

  @-webkit-keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnTopClose {
    0% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @keyframes mobileMenuBtnTopOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, 9px);
      transform: translate(0, 9px);
    }
    100% {
      -webkit-transform: translate(0, 9px) rotate(45deg);
      transform: translate(0, 9px) rotate(45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @keyframes mobileMenuBtnBottomClose {
    0% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
  }
  @-webkit-keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @keyframes mobileMenuBtnBottomOpen {
    0% {
      -webkit-transform: translate(0, 0);
      transform: translate(0, 0);
    }
    50%, 70% {
      -webkit-transform: translate(0, -9px);
      transform: translate(0, -9px);
    }
    100% {
      -webkit-transform: translate(0, -9px) rotate(-45deg);
      transform: translate(0, -9px) rotate(-45deg);
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @keyframes mobileMenuBtnMiddleClose {
    0% {
      opacity: 0;
    }
    50%, 70%, 100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  @keyframes mobileMenuBtnMiddleOpen {
    0% {
      opacity: 1;
    }
    50%, 70%, 100% {
      opacity: 0;
    }
  }
  /* ---- All level menu items ---- */
  #frankenmenu-list, #frankenmenu-list ul {
    width: 100%;
  }

  #frankenmenu-list .menu-item {
    position: relative;
    border-top: 1px solid #F0F0F0;
    float: none;
    width: 100%;
  }

  #frankenmenu-list a {
    min-height: 39px;
    width: 100%;
    padding: 9px 85px 9px 20px;
    color: inherit;
  }

  #frankenmenu-list .menu-item.menu-current-item > a {
    background-color: #23408F;
    color: #ffffff;
  }

  /* ---- First level menu ---- */
  #frankenmenu-list .menu-item-depth1:last-child {
    border-bottom: 1px solid #F0F0F0;
  }

  #frankenmenu-list .menu-item-depth1 > a {
    font-size: 1.5rem;
  }

  /* ---- Submenus ---- */
  #frankenmenu-list .sub-menu {
    display: none;
  }

  #frankenmenu-list .menu-current-item-ancestor > .sub-menu {
    display: block;
  }

  /* Depth differences */
  #frankenmenu-list .menu-item-depth1 > a {
    padding-left: 20px;
    background-color: #ffffff;
  }

  #frankenmenu-list .menu-item-depth2 a {
    font-size: 1.4rem;
  }

  #frankenmenu-list .menu-item-depth2 > a {
    padding-left: 20px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  #frankenmenu-list .menu-item-depth3 > a {
    padding-left: 35px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  #frankenmenu-list .menu-item-depth4 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.4);
  }

  #frankenmenu-list .menu-item-depth5 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  #frankenmenu-list .menu-item-depth6 > a {
    padding-left: 50px;
    background-color: rgba(0, 0, 0, 0.6);
  }

  /* Expand buttons */
  .submenu-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    color: #23408F;
    font-size: 2.3rem;
    width: 55px;
    background: none;
    height: 39px;
    display: block;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 0;
  }

  .submenu-toggle:before,
.submenu-toggle:after {
    content: " ";
    width: 12px;
    height: 3px;
    display: block;
    position: absolute;
    background-color: #23408F;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 22px;
    -webkit-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }

  .submenu-toggle:before {
    -webkit-transform: rotate(45deg) translateX(-5px);
    -ms-transform: rotate(45deg) translateX(-5px);
    transform: rotate(45deg) translateX(-5px);
  }

  .submenu-toggle:after {
    -webkit-transform: rotate(-45deg) translateX(5px);
    -ms-transform: rotate(-45deg) translateX(5px);
    transform: rotate(-45deg) translateX(5px);
  }

  .menu-item-submenu-open > .submenu-toggle:before,
.menu-item-submenu-open > .submenu-toggle:after {
    top: 15px;
  }

  .menu-item-submenu-open > .submenu-toggle:before {
    -webkit-transform: rotate(-45deg) translateX(-5px);
    -ms-transform: rotate(-45deg) translateX(-5px);
    transform: rotate(-45deg) translateX(-5px);
  }

  .menu-item-submenu-open > .submenu-toggle:after {
    -webkit-transform: rotate(45deg) translateX(5px);
    -ms-transform: rotate(45deg) translateX(5px);
    transform: rotate(45deg) translateX(5px);
  }

  /* ---- Sub-navigation ---- */
  #frankenmenu-subnav-list {
    display: none;
  }

  /* ---- Menu positions ---- */
  /* Left */
  .frankenmenu-mobile-pos-left #frankenmenu {
    left: 0;
  }

  .frankenmenu-mobile-pos-left #frankenmenu .container {
    -webkit-transform: translate3d(-10%, 0, 0) scale(0.9);
    transform: translate3d(-10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-left.frankenmenu-mob-menu-visible #wrap {
    -webkit-transform: translate3d(70%, 0, 0);
    transform: translate3d(70%, 0, 0);
  }

  /* Right */
  .frankenmenu-mobile-pos-right #frankenmenu {
    right: -70%;
  }

  .frankenmenu-mobile-pos-right #frankenmenu .container {
    -webkit-transform: translate3d(10%, 0, 0) scale(0.9);
    transform: translate3d(10%, 0, 0) scale(0.9);
  }

  .frankenmenu-mobile-pos-right.frankenmenu-mob-menu-visible #wrap {
    opacity: 0.66;
  }

  .frankenmenu-mobile-pos-right #mobile-menu-button {
    float: right;
  }

  /* ---- Transitions ---- */
  #frankenmenu,
#wrap,
#frankenmenu .container {
    -webkit-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
  }

  .js #frankenmenu {
    opacity: 1;
  }

  /* Menu open */
  .frankenmenu-mob-menu-visible #frankenmenu {
    right: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.2);
  }

  .frankenmenu-mob-menu-visible #frankenmenu .container {
    right: 0;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }

  /* ---- Moved elements ---- */
  .frankenmove {
    display: none;
  }

  /* ---- Megamenu ---- */
  .mega-menu-preview {
    display: none;
  }

  .mega-menu-preview-image {
    background-image: none !important;
  }

  .mega-menu-column-title {
    color: #FFF;
    font-size: 1.5rem;
    padding: 3px 20px;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  #frankenmenu-list .mega-menu {
    display: none;
  }

  #frankenmenu-list .mega-menu ul {
    display: block;
  }

  /* navigation items on mobile */
  #frankenmenu-list .nav__mobile-item {
    display: block;
  }
  #frankenmenu-list .nav__mobile-item a {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #frankenmenu-list .nav__mobile-item svg {
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
  #frankenmenu-list .nav__mobile-item svg path {
    fill: rgba(35, 64, 143, 0.8);
  }
}
@media screen and (min-width: 100em) {
  /* 1600px */
  #frankenmenu-list a {
    font-size: 1.8rem;
  }
}
.sidebar--related-links {
  margin-bottom: 25px;
}

.sidebar--related-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar--related-links a {
  text-decoration: none;
  color: #2a333e;
  display: block;
  padding: 9px 0;
}

/* - Depth 1 - */
.sidebar--related-links li.depth1 {
  border-bottom: 1px solid #e6e1e1;
  position: relative;
}

.sidebar--related-links li.depth1:last-child {
  border-bottom: none;
}

.sidebar--related-links li.depth1 > a {
  padding: 9px 0;
}

/* On */
.sidebar--related-links li.depth1.on > a {
  border-left: 3px solid #35ab75;
  padding-left: 20px;
}

/* - Depth 2 - */
.sidebar--related-links li.depth2 {
  font-size: 1.4rem;
}

.sidebar--related-links li.depth2 a:before {
  content: "- ";
}

.sidebar--related-links li.depth2 a {
  padding: 3px 0 3px 24px;
}

/* Hover */
.sidebar--related-links li > a:hover {
  color: #000;
}

/* On */
.sidebar--related-links li.depth2.on > a {
  color: #35ab75;
  font-weight: bold;
}

.archive-links {
  margin-top: 20px;
}
.archive-links h3 {
  margin-bottom: 8px;
  margin-top: 16px;
}
.archive-links > h3:first-child {
  margin-top: 0;
}
.archive-links .linklist {
  margin-bottom: 0;
}
.archive-links .linklist li {
  padding-top: 0;
  padding-bottom: 0;
}
.archive-links .linklist > li {
  font-size: inherit;
  margin-bottom: 4px;
  margin-top: 16px;
}
.archive-links .linklist > li:first-child {
  margin-top: 0;
}
.archive-links .linklist a {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0;
  color: inherit;
  opacity: 0.66;
  text-decoration: none;
}
.archive-links .linklist a:hover,
.archive-links .linklist a:focus {
  opacity: 1;
}
.archive-links .linklist a:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' preserveAspectRatio='none' viewBox='0 0 10 8'%3E%3Cpath d='M0 4.03h9.006M6.172 6.858l3.19-3.179m-3.19-2.477l3.19 3.184' style='stroke:%235F707E%3B' /%3E%3C/svg%3E");
}
.archive-links .linklist a:hover:after,
.archive-links .linklist a:focus:after,
.archive-links .linklist a:active:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='8' preserveAspectRatio='none' viewBox='0 0 10 8'%3E%3Cpath d='M0 4.03h9.006M6.172 6.858l3.19-3.179m-3.19-2.477l3.19 3.184' style='stroke:%2301232F%3B' /%3E%3C/svg%3E");
}
.archive-links .linklist a.active:after {
  content: "•";
  background: none;
  margin-left: 0;
}
.archive-links .linklist ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.archive-links .linklist h4 {
  padding-left: 10px;
  margin-bottom: 0;
  color: #7f7f7f;
}
.archive-links .linklist h4.st--expanded {
  color: #23408F;
}
.archive-links .linklist h4.st--trigger:hover,
.archive-links .linklist h4.st--trigger:focus {
  background-color: #23408F;
  color: #fff;
}
.archive-links .linklist > ul li a {
  padding-left: 20px;
  display: inline-block;
}
.archive-links .linklist > ul {
  padding-top: 8px;
}

.sitemap {
  list-style: none;
  padding: 0;
  margin: 0;
  /* - Depth 1 - */
  /* - Depth 2 - */
}
.sitemap__item a {
  text-decoration: none;
  color: #474747;
  display: block;
  padding: 9px 0;
}
.sitemap__item a:hover, .sitemap__item a:focus {
  color: #23408F;
}
.sitemap .depth1 {
  position: relative;
}
.sitemap .depth1:last-child {
  border-bottom: none;
}
.sitemap .depth1 > a {
  padding: 9px 0;
}
.sitemap__submenu {
  list-style-type: none;
}
.sitemap .depth2 {
  font-size: 1.4rem;
}
.sitemap .depth2 a:before {
  content: "- ";
}
.sitemap .depth2 a {
  padding: 3px 0 3px 24px;
}

/* ---- Plugins / third-party styles ---- */
/* ---- Magnific Popup ---- */
.mfp-image-holder .mfp-content {
  max-width: 1000px;
}

.mfp-ajax-holder .mfp-content {
  background-color: #fff;
  margin: 0 auto;
  padding: 50px;
  max-width: 800px;
}

/* ---- Slide Toggle ---- */
/* ---- Slide Toggle ---- */
.st--trigger {
  display: block;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
}

.st--trigger:hover,
.st--trigger:focus {
  background-color: #23408F;
  color: #fff;
}

.st--trigger:before,
.st--trigger:after {
  content: " ";
  width: 12px;
  height: 3px;
  display: block;
  position: absolute;
  background-color: #7f7f7f;
  margin: 0 auto;
  right: 10px;
  top: 50%;
  -webkit-transition: all 400ms ease;
  -o-transition: all 400ms ease;
  transition: all 400ms ease;
}

.st--trigger:hover:before,
.st--trigger:focus:before,
.st--trigger:hover:after,
.st--trigger:focus:after {
  background-color: #fff;
}

.st--trigger:before {
  -webkit-transform: rotate(45deg) translateX(-5px);
  -ms-transform: rotate(45deg) translateX(-5px);
  transform: rotate(45deg) translateX(-5px);
}

.st--trigger:after {
  -webkit-transform: rotate(-45deg) translateX(5px);
  -ms-transform: rotate(-45deg) translateX(5px);
  transform: rotate(-45deg) translateX(5px);
}

.st--expanded {
  color: #23408F;
}

.st--trigger.st--expanded:before,
.st--trigger.st--expanded:after {
  top: calc(50% - 6px);
  background-color: #23408F;
}

.st--trigger.st--expanded:hover:before,
.st--trigger.st--expanded:hover:after,
.st--trigger.st--expanded:focus:before,
.st--trigger.st--expanded:focus:after {
  background-color: #fff;
}

.st--trigger.st--expanded:before {
  -webkit-transform: rotate(-45deg) translateX(-5px);
  -ms-transform: rotate(-45deg) translateX(-5px);
  transform: rotate(-45deg) translateX(-5px);
}

.st--trigger.st--expanded:after {
  -webkit-transform: rotate(45deg) translateX(5px);
  -ms-transform: rotate(45deg) translateX(5px);
  transform: rotate(45deg) translateX(5px);
}

/* ---- Blocks ---- */
.date-card {
  background: #23408F;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding: 16px;
  max-width: 94px;
  line-height: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.date-card__day {
  font-size: 3.8rem;
  line-height: 1;
  font-weight: bold;
}

.tile {
  display: block;
  position: relative;
  color: inherit;
  text-decoration: none;
  border: 1px solid #d4d4d4;
  margin-bottom: 40px;
}
@media screen and (min-width: 35em) {
  .tile {
    /* 560px */
    margin-bottom: 0;
  }
}
@media screen and (min-width: 48em) {
  .tile {
    /* 768px */
    display: flex;
    flex-flow: column nowrap;
  }
}
.tile__img img {
  width: 100%;
}
.tile__img--placeholder {
  padding-top: 56.25%;
  background-color: rgba(0, 0, 0, 0.15);
  background-image: url(../images/logo.svg);
}
.tile:hover {
  color: inherit;
}

.tile__content {
  padding: 25px 25px 0 25px;
  flex: 1 1 auto;
}
.tile__content__heading {
  position: relative;
  padding-top: 10px;
}
.tile__content__heading:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 40px;
  background: #00A9E5;
}
.tile__content__cta {
  padding-top: 12px;
  padding-left: 25px;
  margin-bottom: -20px;
}
@media screen and (min-width: 35em) {
  .tile__content__cta {
    /* 560px */
    margin-bottom: 0;
    padding: 15px 25px 25px 25px;
  }
}
.tile__content__text {
  display: none;
}
@media screen and (min-width: 48em) {
  .tile__content__text {
    /* 768px */
    display: block;
  }
}

@media screen and (min-width: 75em) {
  /* 1200px */
  .tile--hover {
    border: none;
  }
  .tile--hover.tile {
    position: relative;
    transition: all 250ms ease-in-out;
  }
  .tile--hover .tile__img {
    position: relative;
    background-color: #000000;
  }
  .tile--hover .tile__img img {
    opacity: 0.85;
  }
  .tile--hover .tile__img:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: 0, 0, 0;
    background: -moz-linear-gradient(16.6deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 33%);
    background: -webkit-linear-gradient(16.6deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 33%);
    background: linear-gradient(16.6deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 33%);
  }
  .tile--hover .tile__content {
    background-color: rgba(0, 0, 0, 0);
    color: #ffffff;
    position: absolute;
    bottom: 66px;
    left: 0;
    right: 0;
    top: calc(100% - 155px);
    overflow: hidden;
    transition: all 250ms ease-in-out;
  }
  @supports (display: flex) {
    .tile--hover .tile__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      animation-fill-mode: both;
      animation-duration: 50ms;
      animation-delay: 150ms;
      animation-name: changeFlexValueBack;
    }
  }
  .tile--hover .tile__content__heading {
    transition: all 250ms ease-in-out;
    color: #ffffff;
    font-size: 2.8rem;
  }
  .tile--hover .tile__content__text {
    opacity: 0;
    transition: all 250ms ease-in-out;
    color: #474747;
  }
  .tile--hover .tile__content__cta {
    padding-top: 0;
    background-color: rgba(255, 255, 255, 0);
    transition: all 250ms ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 66px;
  }

  .tile--hover:hover.tile {
    background-color: #ffffff;
  }
  .tile--hover:hover .tile__content {
    background-color: white;
    opacity: 1;
    top: 0;
  }
  @supports (display: flex) {
    .tile--hover:hover .tile__content {
      animation-fill-mode: forwards;
      animation-duration: 50ms;
      animation-delay: 150ms;
      animation-name: changeFlexValue;
      /* is this madness of genius? At very least unorthodox. */
    }
  }
  .tile--hover:hover .tile__content__heading, .tile--hover:hover .tile__content__text {
    opacity: 1;
    color: #474747;
  }
  .tile--hover:hover .tile__content__cta {
    background-color: white;
  }
  .tile--hover:hover .tile__content .button {
    padding-left: 32px;
    color: #ffffff;
  }
}
@keyframes changeFlexValue {
  0% {
    justify-content: flex-start;
  }
  100% {
    justify-content: center;
  }
}
@keyframes changeFlexValueBack {
  0% {
    justify-content: center;
  }
  100% {
    justify-content: flex-start;
  }
}
.hero-banner {
  background-color: #23408F;
  background-image: url(../assets/banner-background_v2.svg);
}
@media screen and (min-width: 120em) {
  .hero-banner {
    /* 1920px */
    background-size: 100% auto;
  }
}
.hero-banner__item {
  position: relative;
  background-color: #23408F;
  color: #ffffff;
  max-width: 100vw;
}
@media screen and (min-width: 48em) {
  .hero-banner__item {
    padding-top: calc(40vw * 1.77778);
    background: none;
  }
}
@media screen and (min-width: 75em) {
  .hero-banner__item {
    /* 1200px */
    padding-top: 520px;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner__item {
    padding-top: 720px;
  }
}
@media screen and (min-width: 48em) {
  .hero-banner__item--image-landscape .hero-banner__text__inner {
    background-color: rgba(35, 64, 143, 0.75);
  }
}
@media screen and (min-width: 100em) {
  .hero-banner__item--image-landscape .hero-banner__text__inner {
    /* 1600px */
    background-color: transparent;
  }
}
@media screen and (min-width: 75em) {
  .hero-banner {
    height: 520px;
    overflow: hidden;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner {
    height: 720px;
  }
}
.hero-banner__picture {
  width: 100%;
  background: none;
}
@media screen and (min-width: 48em) {
  .hero-banner__picture {
    width: auto;
    padding-top: 0;
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    right: 4vw;
    transform: translateY(-50%);
    box-shadow: 0 12px 32px rgba(0, 0, 0, 0.25);
  }
}
@media screen and (min-width: 75em) {
  .hero-banner__picture {
    right: calc(50vw - 570px);
  }
}
@media screen and (min-width: 100em) {
  .hero-banner__picture {
    right: calc(50vw - 720px);
  }
}
.hero-banner__text {
  padding: 24px 0 40px;
}
.hero-banner__text h2, .hero-banner__text h3 {
  color: #fff;
}
@media screen and (min-width: 48em) {
  .hero-banner__text {
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding: 0;
  }
}
@media screen and (min-width: 48em) {
  .hero-banner__text__inner {
    position: absolute;
    bottom: auto;
    top: 50%;
    left: 0;
    color: #FFF;
    background-color: transparent;
    transform: translateY(-50%);
    padding: 30px;
    z-index: 2;
    width: 60vw;
    padding-left: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 62em) {
  .hero-banner__text__inner {
    width: 50vw;
  }
}
@media screen and (min-width: 75em) {
  .hero-banner__text__inner {
    width: 66.66vw;
    padding-left: calc(50vw - 570px);
    padding-right: 50px;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner__text__inner {
    width: 50vw;
    padding-left: calc(50vw - 720px);
    padding-right: 80px;
  }
}
@media screen and (min-width: 48em) {
  .hero-banner__text .container {
    height: 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner__text__description {
    /* 1600px */
    font-size: 2rem;
  }
}
.hero-banner.slick-dotted.slick-slider {
  margin-bottom: 0;
}
.hero-banner.slick-slider .slick-slide {
  height: auto;
}
.hero-banner .slick-prev,
.hero-banner .slick-next {
  top: calc((56.25vw / 2) - 16px);
  transform: none;
}
.hero-banner .slick-dots {
  top: calc(100vw - 20px);
  bottom: auto;
}
@media screen and (min-width: 48em) {
  .hero-banner .slick-dots {
    top: calc((40vw * 1.77778) - 20px);
  }
}
@media screen and (min-width: 75em) {
  .hero-banner .slick-dots {
    /* 1200px */
    top: 480px;
  }
}
@media screen and (min-width: 100em) {
  .hero-banner .slick-dots {
    top: 680px;
  }
}

.hero-banner__text__inner *:last-child {
  margin-bottom: 0;
}

.inner-banner {
  position: relative;
  min-height: 56.25vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-image: url(../assets/img_blur-1.jpg);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #2b2b2b;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .inner-banner {
    /* 768px */
    background: url(../assets/banner-background_v1.svg);
  }
}
@media only screen and (min-width: 62em) {
  .inner-banner {
    /* 992px */
    min-height: calc(4vw + 92vw * 0.3333);
    background-color: #23408F;
    background-position: top -100px right;
    background-image: url(../assets/banner-background_v1.svg);
  }
}
@media only screen and (min-width: 90em) {
  .inner-banner {
    /* 1440px */
    /*
        half the screen width
        less
        half the container width
        (is the container outside gutter)
        plus
        33.33% of the container width (33.33% of 1140px) * 0.5625 (19x9 ratio)
    */
    min-height: calc(50vw - 570px + 214px);
  }
}
@media screen and (min-width: 100em) {
  .inner-banner {
    /* 1600px */
    min-height: calc(50vw - 720px + 270px);
  }
}
@media screen and (min-width: 120em) {
  .inner-banner {
    /* 1920px */
    min-height: calc(50vw - 720px + 180px);
  }
}
@media screen and (min-width: 150em) {
  .inner-banner {
    /* 2400px */
    background-position: top right;
    min-height: 570px;
  }
}
.inner-banner__img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #23408f;
  background: -moz-linear-gradient(183deg, #23408f 0%, black 100%);
  background: -webkit-linear-gradient(183deg, #23408f 0%, black 100%);
  background: linear-gradient(183deg, #23408f 0%, black 100%);
  z-index: 0;
  opacity: 0.5;
}
@supports (mix-blend-mode: multiply) {
  .inner-banner__img {
    mix-blend-mode: multiply;
    opacity: 0.75;
  }
}
@media screen and (min-width: 48em) {
  .inner-banner__img {
    /* 768px */
    opacity: 1;
    background: none;
    background-image: url(../assets/img_blur-1.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  @supports (mix-blend-mode: multiply) {
    .inner-banner__img {
      mix-blend-mode: normal;
      opacity: 1;
    }
  }
  .inner-banner__img:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #23408f;
    background: -moz-linear-gradient(183deg, #23408f 0%, black 100%);
    background: -webkit-linear-gradient(183deg, #23408f 0%, black 100%);
    background: linear-gradient(183deg, #23408f 0%, black 100%);
    z-index: 0;
    opacity: 0.5;
  }
  @supports (mix-blend-mode: multiply) {
    .inner-banner__img:after {
      mix-blend-mode: multiply;
      opacity: 0.66;
    }
  }
}
@media only screen and (min-width: 62em) {
  .inner-banner__img {
    /* 992px */
    right: calc(4vw + 92vw * 0.6667 + 40px);
  }
  .inner-banner__img:after {
    content: none;
  }
}
@media only screen and (min-width: 90em) {
  .inner-banner__img {
    /* 1440px */
    /*
        half the screen width
        less
        half the container width
        (is the container outside gutter)
        plus
        66.67% of the container width (66.67% of 1140px)
        + 60px of padding to space image away from banner content
    */
    right: calc(50vw - 570px + 820px);
  }
}
@media only screen and (min-width: 100em) {
  .inner-banner__img {
    /* 1600px */
    /*
        half the screen width
        less
        half the container width
        (is the container outside gutter)
        plus
        58.333% of the container width (58.333% of 1140px)
        + 92px of padding to space image away from banner content
    */
    right: calc(50vw - 570px + 757px);
  }
}
.inner-banner .container {
  position: relative;
  z-index: 2;
}
.inner-banner__content h1 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}
@media screen and (min-width: 48em) {
  .inner-banner__content {
    /* 768px */
  }
  .inner-banner__content p {
    font-size: 2rem;
  }
}
@media only screen and (min-width: 62em) {
  .inner-banner__content {
    /* 992px */
    margin-left: 33.33%;
  }
}
@media only screen and (min-width: 100em) {
  .inner-banner__content {
    /* 1600px */
    margin-left: 41.66666667%;
  }
}

.brand-banner {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  color: inherit;
  background-color: #ffffff;
}
@media screen and (min-width: 48em) {
  .brand-banner {
    /* 768px */
    background: none;
  }
}
@media only screen and (min-width: 62em) {
  .brand-banner {
    /* 992px */
    min-height: calc(4vw + 92vw * 0.3333);
    background-color: #23408f;
    background-image: url(../assets/banner-background_v1.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top -100px right;
    color: #fff;
    align-items: flex-end;
    padding-bottom: 60px;
  }
}
@media only screen and (min-width: 90em) {
  .brand-banner {
    /* 1440px */
    /*
        half the screen width
        less
        half the container width
        (is the container outside gutter)
        plus
        33.33% of the container width (33.33% of 1140px) * 0.5625 (19x9 ratio)
    */
    min-height: calc(50vw - 570px + 214px);
  }
}
@media screen and (min-width: 100em) {
  .brand-banner {
    /* 1600px */
    min-height: calc(50vw - 720px + 270px);
  }
}
@media screen and (min-width: 120em) {
  .brand-banner {
    /* 1920px */
    min-height: calc(50vw - 720px + 180px);
  }
}
@media screen and (min-width: 150em) {
  .brand-banner {
    /* 2400px */
    background-position: top right;
    min-height: 570px;
  }
}
.brand-banner__img {
  min-height: 56.25vw;
  background-color: #fff;
  border: 1px solid #d7d7d7;
  z-index: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}
@media only screen and (min-width: 62em) {
  .brand-banner__img {
    /* 992px */
    flex: 0 1 auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: calc(4vw + 92vw * 0.6667 + 40px);
    min-height: unset;
  }
}
@media only screen and (min-width: 90em) {
  .brand-banner__img {
    /* 1440px */
    /*
        half the screen width
        less
        half the container width
        (is the container outside gutter)
        plus
        66.67% of the container width (66.67% of 1140px)
        + 60px of padding to space image away from banner content
    */
    right: calc(50vw - 570px + 820px);
  }
}
.brand-banner__img img {
  max-width: 66.66%;
}
.brand-banner .container {
  position: relative;
  z-index: 2;
}
.brand-banner__content h1 {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}
@media only screen and (min-width: 62em) {
  .brand-banner__content {
    /* 992px */
    margin-left: 33.33%;
  }
}

.brand-banner + .section {
  padding-top: 0;
}
@media only screen and (min-width: 62em) {
  .brand-banner + .section {
    /* 992px */
    padding-top: 60px;
  }
}

.image-gallery {
  margin: 0.5em;
}

.image-gallery-thumb {
  width: 100%;
  display: inline-block;
  float: left;
}

.image-gallery-caption {
  margin-top: 4px;
}

.image-gallery-popup {
  display: block;
}

.image-gallery-thumb img {
  width: 100%;
}

.image-gallery--captions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

@supports (display: grid) {
  .image-gallery {
    margin: 0;
  }

  .image-gallery-thumb {
    width: auto;
    margin: 0;
  }

  .image-gallery {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }

  .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
    content: none;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .image-gallery-thumb {
    width: calc(50% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .image-gallery-thumb {
    width: calc(33% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
/* BP medium */
@media only screen and (min-width: 62em) {
  /* 992px */
  .image-gallery-thumb {
    width: calc(25% - 1em);
    margin: 0.5em;
  }

  @supports (display: grid) {
    .image-gallery {
      margin: 0;
    }

    .image-gallery-thumb {
      width: auto;
      margin: 0;
    }

    .image-gallery {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }

    .image-gallery.-clearfix:before,
.image-gallery.-clearfix:after {
      content: none;
    }
  }
}
.media-object__img {
  float: left;
  width: 100%;
  height: 100%;
}

.media-object__img img {
  width: 100%;
}

.media-object__content {
  width: 100%;
  float: left;
  margin-top: 20px;
}

.media-object {
  padding: 20px;
  width: 100%;
  float: left;
}

.media-object:after,
.media-object:before {
  clear: both;
}

.media-object__details {
  font-weight: bold;
  color: #7f7f7f;
}

.media-object__date:after {
  content: " | ";
}

@media screen and (min-width: 48em) {
  /* 768px */
  .media-object {
    padding: 0;
  }

  .media-object__img {
    width: 33%;
  }

  .media-object__content {
    width: 66%;
    margin-top: 0;
    padding-left: 20px;
  }
}
.section--article {
  background-image: url(../assets/img_blur-1.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.media-article {
  background-color: #fff;
}
.media-article__img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.media-article__inner {
  padding-left: 32px;
  padding-right: 32px;
}
@media screen and (min-width: 22.5em) {
  .media-article__inner {
    /* 360px */
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (min-width: 100em) {
  .media-article {
    margin-left: 5.6667%;
  }
}

.media-article__content {
  position: relative;
  padding-top: 10px;
}
.media-article__content:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 40px;
  background: #00A9E5;
}
.media-article__content > *:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 48em) {
  .media-article {
    position: relative;
  }
  @supports (display: flex) {
    .media-article {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .media-article img {
    display: none;
  }
  @supports (object-fit: cover) {
    .media-article img {
      display: block;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .media-article__inner {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.card {
  background: #ffffff;
  color: #474747;
  padding: 0;
}
@media screen and (min-width: 48em) {
  .card {
    /* 768px */
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.card:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}

.card a:focus {
  text-decoration: underline;
}

.card:focus-within {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.33);
}

.card:focus-within a:focus {
  text-decoration: none;
}

.card__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  order: 1;
}

.card__description--imageless {
  margin-top: 40px;
}

.card__description {
  margin-bottom: auto;
}

.card__description,
.card__hover,
.card__cta {
  padding-left: 15px;
  padding-right: 15px;
}

.card__cta {
  padding-bottom: 15px;
}

.card__heading {
  position: relative;
  padding-top: 10px;
}
.card__heading:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 40px;
  background: #00A9E5;
}
.card__heading a {
  text-decoration: none;
  color: inherit;
}
.card__heading a:hover,
.card__heading .card__heading a:focus {
  color: #F68120;
  opacity: 1;
}
.card__heading-imageless {
  padding-top: 25px;
}

.card__img {
  position: relative;
  padding-top: 56.25%;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
}
.card__img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 66%;
}
.card__img-full {
  overflow: hidden;
}
.card__img-full img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  max-width: 100%;
  object-fit: cover;
}

.card__cta {
  order: 2;
  margin-top: -20px;
}

.card__cta p {
  margin-bottom: 0;
}

.card__img {
  order: 0;
}

.card__description {
  order: 1;
  display: none;
}
.card__description-image {
  margin-top: 20px;
}

@media screen and (min-width: 35em) {
  /* 560px */
  .card {
    border: 1px solid #d4d4d4;
  }
  .card__description {
    display: block;
  }
  .card__img {
    border: none;
  }
  .card__cta {
    margin-top: 15px;
    text-align: left;
  }
}
@media screen and (min-width: 62em) {
  /* 992px */
  .card__description,
.card__hover,
.card__cta {
    padding-left: 25px;
    padding-right: 25px;
  }

  .card__cta {
    padding-bottom: 25px;
  }

  .card__description-image {
    margin-top: 30px;
  }
}
.card-portrait .card__img img {
  max-width: 66.66%;
  max-height: 66.66%;
}
@media screen and (min-width: 35em) {
  .card-portrait .card__img {
    /* 560px */
    padding-top: 33.3%;
  }
}

.card__list--stacked .card {
  border: 1px solid #d4d4d4;
}
@media screen and (min-width: 100em) {
  .card__list--stacked .card {
    border: none;
  }
}
.card__list--stacked .card__img {
  border: none;
}
@media screen and (min-width: 35em) {
  .card__list--stacked .card__img {
    /* 560px */
    flex: 0 33.3%;
  }
}
@media screen and (min-width: 35em) {
  .card__list--stacked {
    /* 560px */
  }
  .card__list--stacked .card__content {
    flex-direction: row;
    align-items: center;
  }
}
.card__list--stacked .card__description {
  display: block;
}
@media screen and (min-width: 35em) {
  .card__list--stacked .card__description {
    /* 560px */
    flex: 0 1 66.6%;
    padding-left: 10px;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    justify-content: center;
  }
}
@media screen and (min-width: 62em) {
  .card__list--stacked .card__description {
    /* 992px */
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 75em) {
  .card__list--stacked .card__description {
    /* 1200px */
    padding-left: 0;
  }
}
.card__list--stacked .card__description__link {
  margin-bottom: -20px;
}
@media screen and (min-width: 35em) {
  .card__list--stacked .card__description__link {
    /* 560px */
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .card__list--stacked .card__description__link {
    /* 1200px */
    margin-top: auto;
  }
}

@media screen and (min-width: 48em) {
  .card__list--brands .card__item--landscape {
    /* 768px */
  }
  @supports (display: grid) {
    .card__list--brands .card__item--landscape {
      grid-column: span 2;
    }
  }
}
.card__list--brands .card__item--landscape .card .card__content {
  display: flex;
  flex-direction: row;
}
.card__list--brands .card__item--landscape .card .card__content .card__img {
  flex: 0 0 auto;
  flex-basis: 50%;
  max-width: 50%;
  padding-top: 28.125%;
}
.card__list--brands .card__item--landscape .card .card__content .card__description {
  margin-top: 32px;
  margin-bottom: 24px;
}
.card__list--brands .card__item--landscape .card .card__content .card__cta--inline {
  margin-bottom: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

.slick-slider {
  margin: 0;
  padding: 0;
  min-height: 100px;
}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  border-radius: 100%;
  background: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.25);
}

.slick-prev {
  background-image: url(../assets/icon-system/icon_arrow-l.svg);
}

.slick-next {
  background-image: url(../assets/icon-system/icon_arrow-r.svg);
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 10px;
}

[dir=rtl] .slick-prev {
  right: 10px;
  left: auto;
}

.slick-prev:before {
  content: none;
}

[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: 10px;
}

[dir=rtl] .slick-next {
  right: auto;
  left: 10px;
}

.slick-next:before {
  content: none;
}

[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  z-index: 1;
  bottom: 10px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #ffffff;
  overflow: hidden;
  opacity: 0.5;
  padding: 0;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  transition: all 250ms ease-in-out;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  opacity: 1;
}

.slick-dots li.slick-active button {
  opacity: 1;
}

.slider-clean {
  position: relative;
}

[aria-label=gallery]:focus {
  outline: 4px solid DodgerBlue;
  outline-offset: -4px;
  /* compensates for 2px border */
}

.slider__buttons {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 16px;
  right: 0;
  left: 0;
  height: auto;
  text-align: center;
}

.slider__button {
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0 4px;
}

.slider__button[type=button] {
  -webkit-appearance: unset;
}

.slider__button a {
  display: block;
  font-size: 1.2rem;
  line-height: 2;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  background: rgba(0, 0, 0, 0.66);
  color: #ffffff;
  text-decoration: none;
}

.slider__gallery {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100vw;
  height: auto;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-type: mandatory;
  -webkit-scroll-snap-points-y: repeat(100vw);
  -ms-scroll-snap-points-y: repeat(100vw);
  scroll-snap-points-y: repeat(100vw);
  -webkit-scroll-snap-type: x mandatory;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
}

.slider__item {
  display: inline-block;
  scroll-snap-align: start;
  float: left;
  width: 100vw;
  flex-shrink: 0;
  height: 100%;
  scroll-behavior: smooth;
}

.slider__item img {
  object-fit: cover;
}

@supports (scroll-snap-type: mandatory) {
  .slider__buttons {
    display: none;
  }
}
.section--brand-bar {
  overflow: hidden;
  font-size: 1.8rem;
  line-height: normal;
  font-weight: bold;
  color: #7f7f7f;
}
.section--brand-bar-slider {
  max-width: calc(88vw + 5px);
  margin-left: -5px;
}
@media screen and (min-width: 48em) {
  .section--brand-bar-slider {
    max-width: 92vw;
  }
}

@media screen and (min-width: 62em) {
  .brand-bar__heading {
    /* 992px */
    margin-bottom: 0;
    font-size: 2.8rem;
    line-height: normal;
  }
  .brand-bar__heading span {
    display: block;
  }
}
@media screen and (min-width: 75em) {
  .brand-bar__heading {
    /* 1200px */
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 100em) {
  .brand-bar__heading {
    /* 1600px */
    font-size: 3.2rem;
  }
}

.img-list-bar {
  list-style-type: none;
  padding: 0;
  margin-left: 0;
  letter-spacing: 0;
}
.img-list-bar:before, .img-list-bar:after {
  content: " ";
  display: table;
}
.img-list-bar:after {
  clear: both;
}
@supports (display: grid) {
  .img-list-bar {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 36em) {
    .img-list-bar {
      grid-gap: 16px;
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media screen and (min-width: 48em) {
    .img-list-bar {
      /* 768px */
    }
  }
  @media screen and (min-width: 62em) {
    .img-list-bar {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .img-list-bar:before, .img-list-bar:after {
    content: none;
  }
}
.img-list-bar__item {
  display: block;
  float: left;
  height: 140px;
  width: 140px;
  padding: 10px 15px 10px 0px;
}
@supports (display: grid) {
  .img-list-bar__item {
    float: none;
    height: 100%;
    width: 100%;
    padding: 0;
  }
}
.img-list-bar__item__imgwrap {
  border: 1px solid #d4d4d4;
  position: relative;
  padding-top: 100%;
  background-color: #ffffff;
}
.img-list-bar__item__imgwrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 66%;
}

/* js--brand-slider */
.img-list-bar.slick-slider {
  display: block;
  min-height: 88px;
}

.img-list-bar__item.slick-slide {
  margin-left: 5px;
  margin-right: 5px;
  float: left;
}

.slick-dotted.slick-slider.img-list-bar {
  margin-bottom: 0;
}
.slick-dotted.slick-slider.img-list-bar .slick-dots {
  bottom: -25px;
}
.slick-dotted.slick-slider.img-list-bar .slick-dots li button {
  background: #7f7f7f;
}

/*
js--fadein-list

.js .img-list-bar__item {
    animation: none;
    opacity: 0;
    transform: translateX(30px);
    transition: all 400ms ease-in-out;
}

.no-js .img-list-bar__item {
    opacity: 1;
    transform: translateX(0px);
}

.img-list-bar__item.is-showing,
.js .img-list-bar__item.is-showing {
    opacity: 1;
    transform: translateX(0px);
}

*/
.section--testimonials {
  background-image: url(../assets/img_blur-2.jpg);
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 50vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.section--testimonials .container {
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
}
@media screen and (min-width: 48em) {
  .section--testimonials .container {
    /* 768px */
    padding-left: 80px;
    padding-right: 80px;
  }
}
@media screen and (min-width: 62em) {
  .section--testimonials .container {
    /* 992px */
    padding-left: 0;
    padding-right: 0;
  }
}

.testimonial {
  text-align: center;
}
@media screen and (min-width: 35em) {
  .testimonial__quote {
    /* 560px */
    font-size: 2rem;
  }
}
@media screen and (min-width: 48em) {
  .testimonial__quote {
    /* 768px */
    font-size: 2.4rem;
  }
}
.testimonial__author {
  color: #7f7f7f;
}
@media screen and (min-width: 48em) {
  .testimonial__author {
    /* 768px */
    font-size: 2rem;
  }
}
.testimonial__cta {
  text-align: center;
  margin-bottom: 0;
}

.testimonial-single {
  background-image: none;
  background-color: #23408F;
}
.testimonial-single .testimonial {
  text-align: left;
}
.testimonial-single .testimonial__title {
  font-size: 2.4rem;
  display: block;
}
@media screen and (min-width: 35em) {
  .testimonial-single .testimonial__title {
    font-size: 2.8rem;
  }
}
.testimonial-single .testimonial__cta {
  text-align: left;
}

.cta-item {
  background-color: #23408F;
  background-image: url(../assets/banner-background_v2.svg);
  background-size: cover;
  background-position: 0 70%;
  color: #ffffff;
  padding: 32px;
}
.cta-item h1, .cta-item .h1,
.cta-item h2, .cta-item .h2,
.cta-item h3, .cta-item .h3,
.cta-item h4, .cta-item .h4,
.cta-item h5, .cta-item .h5 {
  line-height: 1.2;
  color: #ffffff;
}
.cta-item h1, .cta-item .h1 {
  font-size: 4rem;
}
.cta-item h2, .cta-item .h2 {
  font-size: 3.2rem;
  font-weight: 300;
}
.cta-item h3, .cta-item .h3 {
  font-size: 2.4rem;
  color: #636363;
}
.cta-item h4, .cta-item .h4 {
  font-size: 2rem;
}
.cta-item h5, .cta-item .h5 {
  font-size: 1.8rem;
}
.cta-item__cta p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -5px;
  align-items: center;
}
.cta-item__cta p a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #F68120;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  text-transform: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  transition: all 150ms ease-in-out;
  font-size: 1.5rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  padding: 12px 20px 12px;
  margin-bottom: 5px;
}
@media screen and (min-width: 100em) {
  .cta-item__cta p a {
    /* 1600px */
    font-size: 1.8rem;
  }
}
.cta-item__cta p a:hover, .cta-item__cta p a:focus, .cta-item__cta p a:active {
  color: #ffffff;
  background-color: #00A9E5;
  transform: translateY(-2px);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(0, 0, 0, 0.2);
}

.enquiry-form {
  background-color: #23408F;
  color: #ffffff;
  padding: 32px;
}
.enquiry-form h1, .enquiry-form .h1,
.enquiry-form h2, .enquiry-form .h2,
.enquiry-form h3, .enquiry-form .h3,
.enquiry-form h4, .enquiry-form .h4,
.enquiry-form h5, .enquiry-form .h5 {
  line-height: 1.2;
  color: #ffffff;
}
.enquiry-form h1, .enquiry-form .h1 {
  font-size: 4rem;
}
.enquiry-form h2, .enquiry-form .h2 {
  font-size: 3.2rem;
  font-weight: 300;
}
.enquiry-form h3, .enquiry-form .h3 {
  font-size: 2.4rem;
  color: #636363;
}
.enquiry-form h4, .enquiry-form .h4 {
  font-size: 2rem;
}
.enquiry-form h5, .enquiry-form .h5 {
  font-size: 1.8rem;
}
.enquiry-form__cta p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -5px;
  align-items: center;
}
.enquiry-form__cta p a {
  display: inline-block;
  margin-bottom: 0;
  white-space: normal;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #F68120;
  text-decoration: none;
  border-radius: 0;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  text-transform: none;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1;
  transition: all 150ms ease-in-out;
  font-size: 1.5rem;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  padding: 12px 20px 12px;
  margin-bottom: 5px;
}
@media screen and (min-width: 100em) {
  .enquiry-form__cta p a {
    /* 1600px */
    font-size: 1.8rem;
  }
}
.enquiry-form__cta p a:hover, .enquiry-form__cta p a:focus, .enquiry-form__cta p a:active {
  color: #ffffff;
  background-color: #00A9E5;
  transform: translateY(-2px);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(0, 0, 0, 0.2);
}

.cta-item + .enquiry-form {
  margin-top: 40px;
}

.site-introduction {
  text-align: center;
}
.site-introduction > *:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .site-introduction {
    /* 768px */
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 300;
  }
}

/* ---- Forms ---- */
.field-element {
  margin-bottom: 1.5rem;
}

/* ---- Placeholders ---- */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a29999;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #a29999;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #a29999;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #a29999;
}

.field-element--dropdown--placeholder .field-input select {
  color: #a29999;
}

/* ---- Auto-fill color ---- */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #d4d4d4;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #d4d4d4 inset;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  outline: #636363 auto 5px;
  background-color: #d4d4d4;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 80px #d4d4d4 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* All form elements with preceeding icon */
.field-element--datepicker .field-input .textbox,
.field-element--daterangepicker .field-input .textbox,
.field-element--datetimepicker .field-input .textbox,
.field-element--datetimerangepicker .field-input .textbox,
.field-element--money .field-input .textbox,
.field-element--timepicker .field-input .textbox {
  padding-left: 52px;
}

/* Clearfix all field-elements */
.field-element:before,
.field-element:after {
  content: " ";
  display: table;
}

.field-element:after {
  clear: both;
}

.field-element {
  margin-bottom: 1em;
}

/* ---- form colour varients ---- */
/* -- default - grey -- */
.field-input input,
.field-input textarea,
.field-input select {
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F0F0F0;
}

.field-input input:focus,
.field-input textarea:focus,
.field-input select:focus {
  outline: #636363 auto 5px;
}

/* -- white -- */
.field-element--white .field-input input,
.field-element--white .field-input textarea,
.field-element--white .field-input select {
  background-color: #ffffff;
}

/* ---- Disabled ---- */
.field-element--disabled,
.fieldset-input.fieldset-input--disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.field-element--disabled .textbox[disabled],
.field-element--disabled select[disabled],
.field-element--disabled button[disabled],
.field-element--disabled input[type=checkbox] + label,
.fieldset-input.fieldset-input--disabled input[type=checkbox] + label,
.field-element--disabled input[type=radio] + label,
.fieldset-input.fieldset-input--disabled input[type=radio] + label {
  cursor: not-allowed;
}

/* Hidden labels */
.field-element--hidden-label .field-label,
.field-element--hidden-label .fieldset__legend {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-element--hidden-label .field-label.focusable:active, .field-element--hidden-label .field-label.focusable:focus,
.field-element--hidden-label .fieldset__legend.focusable:active,
.field-element--hidden-label .fieldset__legend.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* ---- Required ---- */
.field-label__required {
  text-indent: -9999px;
  line-height: 0;
  display: inline-block;
}

.field-label__required:after {
  content: "*";
  text-indent: 0;
  display: block;
  line-height: inherit;
}

/* ---- Field helpers ---- */
.field-helper {
  font-weight: 400;
  font-size: 1.2rem;
  margin-bottom: 1em;
}

.field-label .field-helper {
  margin-top: 0.3em;
  margin-bottom: 0;
}

/* ---- Field errors ---- */
.field-element.field-element--error .field-input .textbox,
.field-element.field-element--error .field-input select {
  background-color: #fde8e8;
}

.field-error__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.field-error__list__item {
  color: #ffffff;
  background: #9E0E0B;
  padding: 10px 20px;
  margin: 11px 0;
  border-left: 6px solid #C32C2A;
}

/* ---- Attach wrapper ---- */
.field-element-attach-wrapper {
  position: relative;
}

.field-element-attach-wrapper__button {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 0 5px 5px 0;
  background: #b8b8b8;
  border: none;
  outline: none;
  padding: 0 7px;
  z-index: 1;
  cursor: pointer;
  min-width: 45px;
}

.field-element-attach-wrapper__button svg {
  vertical-align: middle;
}

.field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper__button:active,
.field-element-attach-wrapper__button:focus {
  background-color: #9c9c9c;
}

.field-element-attach-wrapper .field-element .field-input .textbox,
.field-element-attach-wrapper .field-element .field-input select {
  padding-right: 55px;
}

/* White attachment wrapper */
.field-element-attach-wrapper--white .field-element-attach-wrapper__button {
  background-color: #d4d4d4;
}

.field-element-attach-wrapper--white .field-element-attach-wrapper__button:hover,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:active,
.field-element-attach-wrapper--white .field-element-attach-wrapper__button:focus {
  background-color: #b8b8b8;
}

/* ---- form style extenders ---- */
.field-element--totalselector__dropdown:before, .daterangepicker.opensright:before {
  position: absolute;
  top: -8px;
  left: 16px;
  display: inline-block;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #9c9c9c;
  border-left: 8px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.field-element--totalselector__dropdown:after, .daterangepicker.opensright:after {
  position: absolute;
  top: -7px;
  left: 17px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: "";
}

.field-element--colorpicker .textbox.colorpicker {
  padding: 2px 8px;
  height: 40px;
  width: 48px;
}

.field-element--datepicker .field-input,
.field-element--daterangepicker .field-input {
  position: relative;
}

.field-element--datepicker .field-input:before,
.field-element--daterangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_datepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Container Appearance */
.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 2px;
  border-radius: 4px;
  width: 278px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.openscenter:before {
  position: absolute;
  top: -7px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #9c9c9c;
  border-left: 7px solid transparent;
  border-bottom-color: #9c9c9c;
  content: "";
}

.daterangepicker.openscenter:after {
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: "";
}

.daterangepicker.dropup {
  margin-top: -5px;
}

.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #9c9c9c;
}

.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #ffffff;
}

.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
  display: none;
  border: 1px solid #9c9c9c;
}

.daterangepicker.single .ranges, .daterangepicker.single .calendar {
  float: none;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-table {
  border: none;
}

/* Calendars */
.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
  border: 1px solid #d4d4d4;
}

.daterangepicker .calendar-table {
  border: 1px solid #b8b8b8;
  padding: 4px;
  border-radius: 4px;
  background: #ffffff;
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border: none;
}

.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  white-space: nowrap;
  padding: 2px;
}

.daterangepicker td,
.daterangepicker th.available {
  cursor: pointer;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  color: #636363;
  background: #F0F0F0;
  border-color: #d4d4d4;
}

.daterangepicker td.off.in-range {
  background-color: #F0F0F0;
}

.daterangepicker td.off.active {
  background-color: #7f7f7f;
  color: #ffffff;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #636363;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background: #66d7ff;
}

.daterangepicker td.in-range {
  background: #7fddff;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #00A9E5;
  color: #ffffff;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #9c9c9c;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect::-ms-expand,
.daterangepicker select.yearselect::-ms-expand {
  display: none;
}

.daterangepicker select.yearselect,
.daterangepicker select.monthselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  margin-bottom: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 62px;
  background-color: #ffffff;
  outline: none;
  border-radius: 4px;
  font-size: 1.4rem;
  line-height: 1.2;
  padding: 5px 23px 5px 8px;
  border: 1px solid #9c9c9c;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 40%;
}

.daterangepicker select.yearselect {
  width: 44%;
}

.daterangepicker th.month {
  width: auto;
}

/* Previous/Next buttons */
.daterangepicker th.prev,
.daterangepicker th.next {
  padding: 1px 2px;
}

.daterangepicker th.prev .daterangepicker-prev-icon,
.daterangepicker th.next .daterangepicker-next-icon {
  width: 12px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;
  display: block;
  margin: 0 auto;
}

.daterangepicker th.next .daterangepicker-next-icon {
  background-image: url(../images/icon-system/icon_form_arrow-right.svg);
}

.daterangepicker th.prev .daterangepicker-prev-icon {
  background-image: url(../images/icon-system/icon_form_arrow-left.svg);
}

.daterangepicker th.prev,
.daterangepicker th.next,
.daterangepicker th.month,
.daterangepicker .calendar-table thead tr:first-child th {
  border: none;
  background: none;
}

.daterangepicker th.prev:hover,
.daterangepicker th.next:hover {
  background: none;
  opacity: 0.8;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  background-color: #F0F0F0;
}

/* Text Input Above Each Calendar */
.daterangepicker .input-mini {
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  color: #474747;
  display: block;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  margin: 0 0 4px 0;
  padding: 0 16px;
  width: 100%;
}

.daterangepicker .input-mini.active {
  border: 1px solid #F68120;
  background-color: #F0F0F0;
}

.daterangepicker .input-mini:focus {
  outline: 4px auto #F68120;
  outline-offset: -2px;
}

.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 7px;
  color: #474747;
}

.daterangepicker .daterangepicker_input {
  position: relative;
}

/* Time Picker */
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #9c9c9c;
  cursor: not-allowed;
}

/* Predefined Ranges */
.daterangepicker .ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}

.daterangepicker .applyBtn {
  background-color: #F68120;
  color: #ffffff;
}

.daterangepicker .applyBtn:hover,
.daterangepicker .applyBtn:focus {
  background-color: #da6809;
  color: #ffffff;
}

.daterangepicker .cancelBtn {
  background-color: #b8b8b8;
  color: #0f0f0f;
}

.daterangepicker .cancelBtn:hover,
.daterangepicker .cancelBtn:focus {
  background-color: #9f9f9f;
  color: #0f0f0f;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto 12px;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 1.3rem;
  background: #d4d4d4;
  border: 1px solid #F0F0F0;
  color: #0f0f0f;
  padding: 4px 12px 3px;
  margin-bottom: 7px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 1.2;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #00A9E5;
  border: 1px solid #00A9E5;
  color: #ffffff;
}

@supports (display: grid) {
  .range_inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8px;
  }
}
/* BP Smaller */
@media screen and (min-width: 35em) {
  /* 560px */
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 160px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker.single .calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
.daterangepicker.single .calendar {
    float: left;
  }

  .daterangepicker .calendar.right {
    margin-left: 0;
  }

  .daterangepicker .left .daterangepicker_input {
    padding-right: 12px;
  }

  .daterangepicker .calendar.left .calendar-table {
    padding-right: 12px;
  }

  .daterangepicker .ranges,
.daterangepicker .calendar {
    float: left;
  }
}
/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .daterangepicker .ranges {
    width: auto;
    float: left;
  }

  .daterangepicker .calendar.left {
    clear: none;
  }
}
/* - Date/time picker - */
.field-element--datetimepicker .field-input,
.field-element--datetimerangepicker .field-input {
  position: relative;
}

.field-element--datetimepicker .field-input:before,
.field-element--datetimerangepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_datetimepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* fieldset styles */
fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

.field-label,
.fieldset__legend {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 8px;
  line-height: 1.2;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
@media screen and (min-width: 100em) {
  .field-label,
.fieldset__legend {
    /* 1600px */
    font-size: 1.8rem;
  }
}

.field-element--checkboxlist--columns .field-element__input-set {
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
}

/* Hide checkbox and radio field element) */
input[type=checkbox],
input[type=radio] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
input[type=checkbox].focusable:active, input[type=checkbox].focusable:focus,
input[type=radio].focusable:active,
input[type=radio].focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

input[type=checkbox] + label,
input[type=radio] + label,
.field-element--small input[type=checkbox] + label,
.field-element--small input[type=radio] + label {
  padding-left: 32px;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

/* -- Field set pseudo elements -- */
input[type=checkbox] + label:before,
input[type=radio] + label:before,
.field-element--small input[type=checkbox] + label:before,
.field-element--small input[type=radio] + label:before {
  content: " ";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  position: relative;
  top: 4px;
  background-color: #F0F0F0;
  border: 1px solid #9c9c9c;
  margin-left: -32px;
}

/* Radio button (pseudo element) */
input[type=radio] + label:before,
.field-element--small input[type=radio] + label:before {
  border-radius: 12px;
}

/* -- Field set pseudo elements : checked -- */
input[type=checkbox]:checked + label:after,
input[type=radio]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  content: " ";
  position: absolute;
  left: 1px;
  top: 6px;
}

/* Checkbox box checked (pseudo element) */
input[type=checkbox]:checked + label:after,
.field-element--small input[type=checkbox]:checked + label:after {
  content: " ";
  background-image: url(../images/icon-system/icon_form_tick-md.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 24px;
  height: 24px;
}

/* Radio dot (pseudo element) */
input[type=radio]:checked + label:after,
.field-element--small input[type=radio]:checked + label:after {
  left: 7px;
  top: 11px;
  width: 9.6px;
  height: 9.6px;
  border-radius: 4.8px;
  background: #474747;
}

/* White */
.field-element--white input[type=checkbox] + label:before,
.field-element--white input[type=radio] + label:before {
  background-color: #ffffff;
}

/* input focus state */
.fieldset--multiradio:focus-within {
  outline: #636363 auto 5px;
}

input[type=checkbox]:focus + label {
  outline: #636363 auto 5px;
  outline-offset: -3px;
}

.field-element--money .field-input {
  position: relative;
}

.field-element--money .field-input .money-symbol:before {
  content: "¤";
  /* Generic currency sign */
  position: absolute;
  display: block;
  left: 2px;
  top: calc(50% - 14px);
  color: #474747;
  border-right: 1px solid #9c9c9c;
  font-weight: bold;
  font-size: 1.8rem;
  width: 40px;
  text-align: center;
}

.field-element--money .field-input .money-symbol--dollar:before {
  content: "$";
  /* Dollars */
}

.field-element--money .field-input .money-symbol--pound:before {
  content: "£";
  /* Pounds */
}

.field-element--money .field-input .money-symbol--yen:before {
  content: "¥";
  /* Yen */
}

.field-element--money .field-input .money-symbol--indian_rupee:before {
  content: "₹";
  /* Indian rupee */
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #474747;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #F68120;
  font-weight: bold;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
  margin-top: -14px;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

input[type=range]:focus::-webkit-slider-thumb {
  background: #F68120;
  box-shadow: 2px 2px 4px #474747;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

input[type=text] {
  display: none;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input select {
  -moz-appearance: none;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-input select[multiple] {
  background-image: none;
  padding-right: 20px;
}

.field-input select::-ms-expand {
  display: none;
}

/* Multiple selects */
.field-element--select--multiple select {
  padding: 8px;
  background-image: none;
}

.field-element--select--multiple option {
  padding: 8px;
}

.field-element--range .field-input .textbox {
  background: none;
  padding: 0;
  border-radius: 0;
  border: none;
  background: none !important;
}

.field-element--range .field-input input[type=range] + div {
  color: #474747;
  font-weight: bold;
}

.field-element--range .field-input input[type=range]:focus + div {
  color: #F68120;
  font-weight: bold;
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
  /* Otherwise white in Chrome */
  margin: 0;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: #9c9c9c;
  border-radius: 4px;
  border: none;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
  margin-top: -14px;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #9c9c9c;
}

input[type=range]:focus::-webkit-slider-thumb {
  background: #F68120;
  box-shadow: 2px 2px 4px #474747;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: #b8b8b8;
  border-radius: 4px;
  border: none;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 18px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-fill-upper {
  background: #b8b8b8;
  border: none;
  border-radius: 4px;
  box-shadow: none;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 2px #2e2e2e;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background: #474747;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #b8b8b8;
}

input[type=range]:focus::-ms-fill-upper {
  background: #b8b8b8;
}

input[type=text] {
  display: none;
}

/* IE hack fix -
if not IE, apply these styles
has nothing to do with (display:block)
and everything to do with IE not supporting @supports */
@supports (display: block) {
  input[type=range] {
    margin: 12.8px 0;
  }
}
.field-input .textbox,
.field-input select,
.field-element--fileselector .fs-preview-wrapper {
  display: block;
  width: 100%;
  outline: none;
  line-height: normal;
  padding: 10px 16px 10px;
  -webkit-appearance: none;
  line-height: 1.4;
}

/* time picker */
.field-element--timepicker .field-input {
  position: relative;
}

.field-element--timepicker .field-input:before {
  content: " ";
  display: block;
  position: absolute;
  left: 2px;
  top: calc(50% - 12px);
  width: 40px;
  background-image: url(../images/icon-system/icon_form_timepicker.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  border-right: 1px solid #9c9c9c;
}

/* Timepicker modal */
.ui-timepicker {
  display: block;
  position: absolute;
  height: 200px;
  overflow: auto;
  z-index: 9999 !important;
}

.ui-timepicker-standard {
  background-color: #ffffff;
  border: 1px solid #9c9c9c;
  display: block;
  margin: 4px 0 0;
  padding: 6px;
  list-style: none outside none;
  border-radius: 4px;
}

.ui-timepicker-standard a {
  color: #0f0f0f;
}

.ui-timepicker-standard .ui-state-hover {
  background-color: #d4d4d4;
}

.ui-timepicker-standard .ui-menu-item {
  clear: left;
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ui-timepicker-standard .ui-menu-item a {
  display: block;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  border-radius: 4px;
}

.ui-timepicker-hidden {
  display: none;
}

.field-element--totalselector {
  position: relative;
}

.field-element--totalselector .field-input .total-selector__output {
  cursor: pointer;
  background-image: url(../images/icon-system/icon_form_arrow-down.svg), url(../images/form_bg-fill.svg);
  background-repeat: no-repeat, repeat-Y;
  background-position: center right 8px, center right;
  background-size: 24px, 40px;
  padding-right: 52px;
}

.field-element--totalselector__dropdown {
  position: absolute;
  background: #ffffff;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px 24px;
  margin-top: 2px;
  border-radius: 4px;
  z-index: 800;
  border: 1px solid #9c9c9c;
  display: none;
  white-space: nowrap;
}

.field-element--totalselector.field-element--totalselector--active .field-element--totalselector__dropdown {
  display: block;
}

.total-selector__dropdown__field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 16px;
}

.total-selector__dropdown__field:last-child {
  margin-bottom: 0;
}

.total-selector__dropdown__field__labels {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.total-selector__dropdown__field__buttons {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.total-selector__dropdown__field__button {
  -webkit-appearance: none;
  border: 1px solid #7f7f7f;
  border-radius: 4px;
  outline: none;
  background: none;
  padding: 0;
  height: 32px;
  width: 32px;
  color: #636363;
  cursor: pointer;
  font-size: 1.4rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: center center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--decrease {
  background-image: url(../images/icon-system/icon_form_minus.svg);
  background-position: left 8px -1 center;
}

.total-selector__dropdown__field__button.total-selector__dropdown__field__button--increase {
  background-image: url(../images/icon-system/icon_form_plus.svg);
  background-position: right 8px -1 center;
}

.total-selector__dropdown__field__button:focus {
  color: #636363;
  border-color: #636363;
  background-color: #F0F0F0;
}

.total-selector__dropdown__field__button:hover {
  color: #636363;
  border-color: #636363;
}

/* Min / max */
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--min,
.total-selector__dropdown__field__button.total-selector__dropdown__field__button--max {
  opacity: 0.5;
  pointer-events: none;
  color: #636363;
  border-color: #b8b8b8;
}

.total-selector__dropdown__field__total {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 4px 6px;
  width: 50px;
  text-align: center;
  font-size: 1.8rem;
}

.total-selector__dropdown__field__labels p {
  display: block;
  margin-right: 8px;
}

.total-selector__dropdown__field__labels__title {
  margin: 0;
  font-size: 1.5rem;
}

.total-selector__dropdown__field__labels__helper {
  margin: 0;
  font-size: 1.2rem;
}

.field-element--totalselector__fields {
  display: none;
}

/* Done button */
.total-selector__dropdown__close__button-wrap {
  text-align: right;
  padding-top: 10px;
}

/* BP Extra small */
@media only screen and (min-width: 37.5em) {
  /* 600px */
  .field-element--totalselector__dropdown {
    right: auto;
    min-width: 320px;
  }

  .total-selector__dropdown__field__labels__helper {
    font-size: 1.5rem;
    line-height: 1;
  }

  .total-selector__dropdown__field {
    margin: 0 0 24px;
  }
}
/* BP Small */
@media only screen and (min-width: 48.063em) {
  /* 769px */
  /* Don't really need this close button above tablet */
  .total-selector__dropdown__close__button-wrap {
    display: none;
  }
}
.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  background: #000;
  padding: 12px 16px;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F0F0F0;
  transition: all 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #d4d4d4;
  border: 1px solid #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #d4d4d4;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline: #636363 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #d4d4d4;
  border: 1px solid #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #d4d4d4;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.submit-bar {
  text-align: right;
  background: #d4d4d4;
  padding: 16px;
  margin: 16px 0;
}

.sidebar .submit-bar {
  padding: 12px 0;
}

.submit-bar a {
  margin-right: 20px;
}

.field-element--button .button {
  padding-top: 14px;
  padding-bottom: 14px;
}
@media screen and (min-width: 48em) {
  .field-element--button .button {
    /* 768px */
    padding-top: 14px;
    padding-bottom: 14px;
  }
}

.site-search-form {
  margin: 0 0 40px;
}

.site-search-form--header {
  margin: 0;
}

.search-result {
  border-top: 1px solid #9c9c9c;
  margin: 20px 0;
  padding: 20px 0;
}

.search-result p:last-child {
  margin-bottom: 0;
}

.g-recaptcha {
  padding-bottom: 15px;
}

.field-element--upload .field-input input[type=file] {
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

input[type=file]::-webkit-file-upload-button {
  padding: 4px 8px;
  border: 1px solid #9c9c9c;
  border-radius: 4px;
  background-color: #F0F0F0;
  transition: all 250ms ease-in-out;
  outline: none;
}

input[type=file]:hover::-webkit-file-upload-button {
  background-color: #d4d4d4;
  border: 1px solid #838383;
}

input[type=file]:focus::-webkit-file-upload-button {
  background-color: #d4d4d4;
  outline: none;
}

input[type=file]:active::-webkit-file-upload-button {
  outline: none;
  background-color: #b8b8b8;
}

input[type=file]:focus {
  outline: #636363 auto 5px;
}

input[type=file i]:focus {
  outline-offset: 0;
}

/* ---- white ---- */
.field-element--white input[type=file]::-webkit-file-upload-button {
  background-color: #ffffff;
}

.field-element--white input[type=file]:hover::-webkit-file-upload-button {
  background-color: #d4d4d4;
  border: 1px solid #838383;
}

.field-element--white input[type=file]:focus::-webkit-file-upload-button {
  background-color: #d4d4d4;
}

.field-element--white input[type=file]:active::-webkit-file-upload-button {
  background-color: #b8b8b8;
}

.field-element--upload-inline {
  background-color: #F0F0F0;
  padding: 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}
.field-element--upload-inline label {
  display: block;
  cursor: pointer;
  font-weight: bold;
}
.field-element--upload-inline label:after {
  content: "select";
  font-weight: normal;
  background: #00A9E5;
  color: #ffffff;
  padding: 4px 16px;
  border-radius: 4px;
  margin: 0 0 0 16px;
  transition: all 250ms ease-in-out;
}
.field-element--upload-inline label:hover:after {
  content: "select";
  background-color: #007199;
}
.field-element--upload-inline input:focus + label,
.field-element--upload-inline input:active + label {
  outline: #636363 auto 5px;
}
.field-element--upload-inline input[type=file] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

/* ------------------------------------------------------
**** LAYOUTS
------------------------------------------------------ */
/* ---- Global ---- */
@media screen and (min-width: 62em) {
  /* 992px */
  .sticky-nav-active #header {
    height: 128px;
  }
  .sticky-nav-active .section--header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
  }
}
.section--header {
  background: #ffffff;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 62em) {
  .section--header {
    /* 992px */
    position: relative;
    z-index: 98;
    padding-top: 20px;
    padding-bottom: 16px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    transition: all 200ms ease-in-out;
  }
}

.header__search .field-element {
  margin-bottom: 0;
}

.header__logo a {
  display: block;
}

.logo__img {
  height: 92px;
  width: 236px;
}

.header__rhs {
  display: none;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (min-width: 35em) {
  .header__rhs {
    display: flex;
  }
}

.header__topnav {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
  background: #00A9E5;
  background: -moz-linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
  background: -webkit-linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
  background: linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
  color: #ffffff;
  padding-bottom: 12px;
  padding-top: 12px;
  position: relative;
  z-index: 0;
}
.header__topnav:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -24px;
  border-style: solid;
  border-width: 48px 24px 0 24px;
  border-color: #23408F transparent transparent transparent;
  z-index: -1;
}
.header__topnav:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: calc(100% - 1px);
  width: 300px;
  background-color: #00A9E5;
  z-index: -1;
}
@media screen and (min-width: 90em) {
  .header__topnav:after {
    /* 1400px */
    width: calc(50vw - 570px);
  }
}
.header__topnav .header__topnav__item {
  padding-left: 16px;
  padding-right: 16px;
}
.header__topnav .header__topnav__item svg path {
  fill: #ffffff;
}
.header__topnav .header__topnav__item a {
  display: block;
  text-decoration: none;
  color: #ffffff;
}
.header__topnav .header__topnav__item--contact a {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.header__topnav .header__topnav__item--contact span,
.header__topnav .header__topnav__item--contact svg {
  margin-left: 4px;
  margin-right: 4px;
}
.header__topnav .header__topnav__item--insta svg, .header__topnav .header__topnav__item--linkedin svg {
  height: 1.5em;
  width: 1.5em;
}
.header__topnav .header__topnav__item--insta {
  padding-left: 8px;
}
.header__topnav .header__topnav__item--linkedin {
  padding-right: 8px;
}

.section--locations-panel {
  background-color: #23408F;
  background-image: url(../assets/banner-background_v2.svg);
  background-size: cover;
  color: #ffffff;
}
.section--locations-panel h2, .section--locations-panel h3, .section--locations-panel h4, .section--locations-panel h5, .section--locations-panel h6 {
  color: inherit;
}
.section--locations-panel ul {
  list-style-type: none;
  padding-left: 0;
  text-align: center;
}

.section--testimonials + .section--locations-panel {
  border-top: 0.5px solid #fff;
}

.section--pre-footer {
  background-color: #F0F0F0;
  position: relative;
}
.section--pre-footer .col-md-4 {
  margin-bottom: 32px;
}
@media screen and (min-width: 62em) {
  .section--pre-footer .col-md-4 {
    /* 992px */
    margin-bottom: 0;
  }
}

.footer__heading--linkslist {
  position: relative;
  padding-top: 10px;
}
.footer__heading--linkslist:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 40px;
  background: #00A9E5;
}

.footer-subtitle {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.33em;
}

.footer__contact-details {
  margin-bottom: 0.5em;
}

.section--footer {
  background: #00A9E5;
  background: -moz-linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
  background: -webkit-linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
  background: linear-gradient(90deg, #23408f 5%, #00a9e5 100%);
  color: #ffffff;
  color: #ffffff;
  padding: 32px 0;
  text-align: center;
}
.section--footer a {
  color: inherit;
}
.section--footer a:hover, .section--footer a:focus, .section--footer a:active {
  color: inherit;
  opacity: 0.75;
}

.footer__text {
  padding-top: 16px;
  padding-bottom: 16px;
}

.footer__text p {
  margin: 0;
}
@media screen and (min-width: 35em) {
  .footer__text p {
    /* 560px */
    display: inline;
  }
  .footer__text p:before {
    content: " ";
  }
  .footer__text p:first-child:before {
    content: none;
  }
}

.footer__logos {
  border: 1px solid #d4d4d4;
  position: relative;
  padding-top: 100%;
  background-color: transparent;
  display: block;
}

.footer__logos img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 66%;
}

@media screen and (min-width: 75em) {
  @supports (filter: grayscale(1)) {
    .footer__logos img {
      filter: grayscale(1);
      transition: filter 150ms ease-in-out;
    }

    .footer__logos:hover img,
.footer__logos:focus img {
      filter: grayscale(0);
    }
  }
}
.footer__logos--brand-sa {
  margin-bottom: 1rem;
}

.footer__logos--airah {
  height: 100%;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .section--pre-footer .block-list {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 40px;
    -moz-column-gap: 40px;
    column-gap: 40px;
  }

  .section--footer {
    text-align: auto;
  }

  .footer__text {
    padding: 0;
  }
}
.section--footer .social-list__item {
  margin-left: 8px;
  margin-right: 8px;
}

.sidebar-item > *:last-child {
  margin-bottom: 0;
}

.sidebar--cta {
  padding: 32px;
  margin-top: 40px;
}

@media screen and (min-width: 48em) {
  .sidebar {
    padding-right: 60px;
  }
}
@media only screen and (min-width: 48em) and (min-width: 100em) {
  .sidebar {
    /* 1600px */
    padding-right: 92px;
  }
}
.tile-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tile-list:before, .tile-list:after {
  content: " ";
  display: table;
}
.tile-list:after {
  clear: both;
}
.tile-list__item {
  margin-bottom: 25px;
}

@media screen and (min-width: 48em) {
  /* 768px */
  .tile-list {
    margin: 0 -15px;
    display: flex;
    flex-flow: row wrap;
  }
  .tile-list__item {
    margin-bottom: 30px;
    width: 50%;
    padding: 0 15px 0;
    float: left;
  }
  .tile-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 75em) {
  /* 1200px */
  .tile-list {
    margin: 0;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  }
  .tile-list__item {
    margin-bottom: 0;
    padding: 0;
  }
}
/* ---- top-heavy-list ---- */
/* To display a list of products nicely */
.top-heavy-list {
  width: 100%;
  list-style: none;
  padding: 20px 0 30px;
  margin: 0 -10px;
}
.top-heavy-list:before, .top-heavy-list:after {
  content: " ";
  display: table;
}
.top-heavy-list:after {
  clear: both;
}
.top-heavy-list > .top-heavy-list {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
.top-heavy-list + .top-heavy-list {
  padding-top: 0;
}
.top-heavy-list__item {
  padding: 10px;
}

@media screen and (min-width: 35em) {
  /* 560px */
  .top-heavy-list__item {
    padding: 10px;
    width: 50%;
    float: left;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 62em) {
  /* 992px */
  .top-heavy-list__item {
    width: 33.3333%;
  }
  .top-heavy-list__item:nth-child(2n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: left;
  }
}
@media screen and (min-width: 90em) {
  /* 1400px */
  .top-heavy-list__item {
    width: 25%;
  }
  .mainbar--narrow .top-heavy-list__item {
    width: 33.33333333%;
  }
  .top-heavy-list__item:nth-child(3n+1) {
    clear: none;
  }
  .top-heavy-list__item:nth-child(4n+1) {
    clear: left;
  }
}
/* Flex code */
@supports (display: flex) {
  @media screen and (min-width: 35em) {
    /* 560px */
    .top-heavy-list {
      display: flex;
      flex-flow: row wrap;
      margin-left: -10px;
      margin-right: -10px;
      width: calc(100% + 20px) .top-heavy-list __item;
      width-margin-right: auto;
      width-margin-left: auto;
      width-padding: 0 10px 20px;
      width-flex-basis: 50%;
      width-min-width: 50%;
      width-width: auto !important;
    }
    .top-heavy-list--five .top-heavy-list__item, .top-heavy-list__item:nth-child(-n+3) {
      flex-grow: 1;
    }
  }
  @media screen and (max-width: 991px) {
    /* 991px */
    .top-heavy-list--three .top-heavy-list__item:first-child, .top-heavy-list--five .top-heavy-list__item:first-child, .top-heavy-list--seven .top-heavy-list__item:first-child, .top-heavy-list--odd .top-heavy-list__item:first-child {
      flex-basis: 100%;
    }
  }
  @media screen and (min-width: 62em) {
    /* 992px */
    .top-heavy-list {
      margin-left: -2%;
      margin-right: -2%;
      /*  grid with sidebar, stays only 3 columns  */
    }
    .top-heavy-list .top-heavy-list__item {
      padding: 2%;
      flex-basis: 25%;
      min-width: 25%;
    }
    .top-heavy-list--odd > .top-heavy-list__item, .mainbar--narrow .top-heavy-list > .top-heavy-list__item, .top-heavy-list--six > .top-heavy-list__item, .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+3) {
      flex-basis: 33.33333333%;
      min-width: 33.33333333%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
    .top-heavy-list--five > .top-heavy-list__item:nth-child(n+3) {
      flex-basis: 20%;
    }
    .mainbar--narrow .top-heavy-list--four > .top-heavy-list__item:nth-child(-n+4), .mainbar--narrow .top-heavy-list--seven > .top-heavy-list__item:nth-child(-n+4) {
      flex-basis: 50%;
    }
    .mainbar--narrow .top-heavy-list--five > .top-heavy-list__item:nth-child(-n+2), .mainbar--narrow .top-heavy-list--even > .top-heavy-list__item:nth-child(-n+2) {
      flex-basis: 50%;
    }
  }
}
.media-object__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.media-object__list:before, .media-object__list:after {
  content: " ";
  display: table;
}
.media-object__list:after {
  clear: both;
}

.media-object__item {
  padding: 10px 0;
}

.media-object__list:after {
  clear: both;
}

.card__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

/* BP small */
@media screen and (min-width: 48em) {
  /* 768px */
  .card__list {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -16px;
    margin-bottom: -16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .card__list:before, .card__list:after {
    content: " ";
    display: table;
  }
  .card__list:after {
    clear: both;
  }

  .card__item {
    flex: 1 1 auto;
    max-width: 100%;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @supports (display: grid) {
    .card__list {
      margin-top: 0;
      margin-bottom: 0;
    }

    .card__item {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .card__list--brands .card__item {
    flex: 1 1 50%;
    max-width: calc(50% - 32px);
  }
  @supports (display: grid) {
    .card__list--brands .card__item {
      max-width: 100%;
    }
  }
}
@supports (display: grid) {
  .card__list {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
  .card__list .card__item {
    width: 100%;
  }
  @media screen and (min-width: 48em) {
    .card__list {
      /* 768px */
      margin-left: 0;
      margin-right: 0;
    }
    .card__list:after, .card__list:before {
      content: none;
    }
    .card__list .card__item {
      width: 100%;
    }
  }

  .card__list--brands {
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }
  @media screen and (min-width: 48em) {
    .card__list--brands {
      /* 768px */
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr auto;
      grid-gap: 32px;
    }
  }

  .card__list--lg {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
  @media screen and (min-width: 35em) {
    .card__list--lg {
      /* 560px */
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }
  }

  .card__item + .card__item {
    margin-top: 0;
  }
}
@media screen and (min-width: 48em) {
  /* 768px */
  .card__list--2 .card__item,
.card__list--4 .card__item,
.card__list--8 .card__item {
    flex: 1 1 50%;
    max-width: calc(50% - 32px);
  }

  .card__list--3 .card__item,
.card__list--5 .card__item,
.card__list--6 .card__item,
.card__list--7 .card__item,
.card__list--9 .card__item {
    flex: 1 1 33%;
    max-width: calc(33% - 32px);
  }

  @supports (display: grid) {
    .card__list--2,
.card__list--4,
.card__list--8 {
      grid-template-columns: 1fr 1fr;
    }
    .card__list--2 .card__item,
.card__list--4 .card__item,
.card__list--8 .card__item {
      width: 100%;
      max-width: 100%;
    }

    .card__list--3,
.card__list--5,
.card__list--6,
.card__list--7,
.card__list--9 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .card__list--3 .card__item,
.card__list--5 .card__item,
.card__list--6 .card__item,
.card__list--7 .card__item,
.card__list--9 .card__item {
      width: 100%;
      max-width: 100%;
    }
  }
}
@media screen and (min-width: 75em) {
  .card__list--4 .card__item,
.card__list--8 .card__item {
    flex: 1 1 25%;
    max-width: calc(25% - 32px);
  }

  @supports (display: grid) {
    .card__list--4,
.card__list--8 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .card__list--4 .card__item,
.card__list--8 .card__item {
      width: 100%;
      max-width: 100%;
    }
  }
}
/* ------------------------------------------------------
**** MODULES
------------------------------------------------------ */
/* ------------------------------------------------------
**** A new world order. Craft.
------------------------------------------------------ */
/*
 * aka, you're permitted to put core sprout styles in here
 * as they won't be included in the usual sprout needs helper
 */
.post-list__item {
  margin-bottom: 1em;
  padding: 0.5em 0;
  border-bottom: 1px solid #d6d6d6;
}

.post-sidebar-section {
  margin: 0 0 32px;
}

.post-sidebar-archive-list,
.post-sidebar-related-list {
  list-style: none;
  padding: 0;
}

.js .sidebar-linklist__item__sublist {
  display: none;
}

.post-view__image {
  margin-bottom: 16px;
}

.post-view__header {
  text-transform: uppercase;
  font-size: 0.95em;
}

.sidebar-linklist {
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item {
  border-bottom: 1px solid #d6d6d6;
  position: relative;
}

.sidebar-linklist__item__button {
  display: block;
  background: none;
  border: none;
  padding: 10px 18px 10px 1.5em;
}

.sidebar-linklist__item__button:before {
  content: ">";
  position: absolute;
  left: 0;
  color: #000;
  top: calc(10px + 0.1em);
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transform-origin: center;
  transform-origin: center;
}

.post-sidebar-archive-list__item--expanded .sidebar-linklist__item__button:before,
.sidebar-linklist__item:hover .sidebar-linklist__item__button:before {
  left: 0.5em;
}

.post-sidebar-archive-list__item--expanded .sidebar-linklist__item__button:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sidebar-linklist__item__sublist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-linklist__item__sublist__item {
  list-style: none;
}

.sidebar-linklist__item__sublist__item__link:before {
  content: "– ";
  color: #000;
}

.sidebar-linklist__item__sublist__item__link {
  display: block;
  text-decoration: none;
  padding: 0.25em 0.25em 0.25em 1.5em;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.sidebar-linklist__item__sublist__item__link:hover,
.sidebar-linklist__item__sublist__item__link:focus,
.sidebar-linklist__item__sublist__item__link:active {
  background: rgba(0, 0, 0, 0.05);
}

/* Post hub */
.post-hub__item--hidden,
.post-hub--unfiltered .post-hub__item--hidden,
.post-hub--filtered .post-hub__item--filtered-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.post-hub--unfiltered .post-hub__item--animate {
  animation: fadeIn 300ms ease;
  animation-fill-mode: forwards;
}

.post-hub--filtered .post-hub__item--filtered-pre-visible {
  opacity: 0;
}

.post-hub--unfiltered .post-hub__item--animate-grow,
.post-hub--filtered .post-hub__item--filtered-visible {
  animation: growInFade 350ms ease;
  animation-fill-mode: forwards;
}

.post-hub__item__image-loading .post-hub__img {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.post-hub__img-link {
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  display: block;
}

.post-hub__img,
.post-hub__img-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.post-hub__img-placeholder {
  filter: blur(6px);
  transform: scale(1.05);
  transition: all 500ms ease;
  z-index: 1;
}

.post-hub__img-link-fallback .post-hub__img {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: 66.66%;
  max-width: 240px;
}

.post-hub__item__image-loaded .post-hub__img-placeholder {
  opacity: 0;
  transform: none;
  transition-duration: 1.5s;
}

/* ---- Category lists ---- */
.post-hub-filterlist {
  list-style-type: none;
  padding: 0;
  font-size: 0.85em;
  text-transform: uppercase;
}

.field-element.field-element--post-hub-filterlist {
  margin-bottom: 35px;
}

.js-post-hub-filterlist .js-post-hub-filterlist__select {
  margin-bottom: 24px;
}

/* Hides the list if js is working, defaults to working list if not */
@media screen and (max-width: 37.4375em) {
  /* 599px */
  .js .js-post-hub-filterlist .post-hub-filterlist {
    display: none;
  }
}
@media screen and (min-width: 37.5em) {
  /* 600px */
  .post-hub-filterlist {
    text-align: center;
  }

  .post-hub-filterlist__item {
    display: inline-block;
  }

  .js-post-hub-filterlist__select {
    display: none !important;
  }
}
/*
 * NOTE: [modules:01]
 * DO NOT PUT core module css here
 * the following are for bespoke skin styles that are ADDITIONAL to core module css
 */
@media screen and (min-width: 48em) {
  .post-hub--grid {
    margin-left: -16px;
    margin-right: -16px;
  }

  .post-hub--grid .post-hub__item {
    width: calc(33.3% - 32px);
    margin-left: 16px;
    margin-right: 16px;
  }

  .post-hub--grid .post-hub__item__content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @supports (display: grid) {
    .post-hub--grid {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: repeat(3, 1fr);
      margin-left: 0;
      margin-right: 0;
    }
    @media only screen and (min-width: 48em) and (min-width: 62em) {
      .post-hub--grid {
        grid-gap: 40px;
      }
    }

    .post-hub--grid.-clearfix:before,
.post-hub--grid.-clearfix:after {
      content: none;
    }

    .post-hub--grid .post-hub__item {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
.post-hub__body h2 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 0;
}
@media screen and (min-width: 100em) {
  .post-hub__body h2 {
    font-size: 2.4rem;
  }
}
.post-hub__body h2 a {
  color: inherit;
  text-decoration: none;
}
.post-hub__body p {
  color: #474747;
}
.post-hub__body .post-list__date {
  color: #7f7f7f;
}
.post-hub__footer {
  margin-top: auto;
}
.post-hub__footer p {
  margin-bottom: 0;
}

/* single post */
.entry-date {
  color: #7f7f7f;
}

.inner-banner .entry-date {
  color: #ffffff;
}

.post-listing .post-hub__item {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d7d7d7;
}

/* ------------------------------------------------------
**** PAGES
------------------------------------------------------ */
/* ------------------------------------------------------
**** Print styles
------------------------------------------------------ */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  /* Don't show links for images, or javascript/internal links */
  .ir a:after,
a[href^="javascript:"]:after,
a[href^="#"]:after {
    content: "";
  }

  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
    /* h5bp.com/t */
  }

  tr,
img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }
  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }
}