.txt-c-primary {
	color: $color-primary;
}

.txt-c-secondary {
	color: $color-secondary;
}

.txt-c-alternative {
	color: $color-alternative;
}

.txt-c-accent-01 {
	color: $color-accent-01;
}

.txt-c-accent-02 {
	color: $color-accent-02;
}


// Neutral Colours

.txt-c-white {
	color: $color-white;
}

.txt-c-grey-01 {
	color: $color-grey-01;
}

.txt-c-grey-02 {
	color: $color-grey-02;
}

.txt-c-grey-03 {
	color: $color-grey-03;
}

.txt-c-grey-04 {
	color: $color-grey-04;
}

.txt-c-grey-05 {
	color: $color-grey-05;
}

.txt-c-grey-06 {
	color: $color-grey-06;
}

.txt-c-grey-07 {
	color: $color-grey-07;
}

.txt-c-grey-08 {
	color: $color-grey-07;
}

.txt-c-grey-09 {
	color: $color-grey-09;
}

.txt-c-black {
	color: $color-black;
}
