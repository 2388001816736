
.container {
    width: 88%;
    max-width: 1140px;
    margin: 0 auto;
}

/* BP small */
@media screen and (min-width: 48em) { /* 768px */
    .container {
        width: 92%;
    }
}

@media screen and (min-width: 100em) { /* 1600px */
    .container--lg {
        max-width: 1440px;
    }
}

