h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    margin: 0 0 1.5rem 0;
    font-family: $accent-font;
    font-weight: bold;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    font-weight: normal;
    line-height: 1;
    color: #8a7b7b;
}

p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6 {
    margin-top: 3rem;
}


h1, .h1 {
    font-size: 3.2rem;
    line-height: 1.2;
}

h2, .h2 {
    font-size: 2.8rem;
    line-height: 1.4;
    color: $color-primary;
}

h3, .h3 {
    font-size: 2.2rem;
    margin-bottom: 0;
}

h4, .h4 {
    font-size: 1.8rem;
    margin-bottom: 0;
    color: $color-secondary;
}

h5, .h5 {
    font-size: 1.8rem;
    margin-bottom: 0;
}

h6, .h6 {
    font-size: $font-regular;
}

p,
ol,
ul,
dl,
address {
    margin: 0 0 1.5rem;
}

small {
    font-size: 1.28rem;
}


/* BP Smaller */
@media screen and (min-width: 35em) { /* 560px */

    h1, .h1 {
        font-size: 4rem;
    }

    h2, .h2 {
        font-size: 3.2rem;
    }

    h3, .h3 {
        font-size: 2.4rem;
        margin-bottom: 0.75rem;
    }

    h4, .h4 {
        font-size: 2rem;
    }

    h5, .h5 {
        font-size: 1.8rem;
        margin-bottom: 0;
    }

}

@media screen and (min-width: 100em) { /* 1600px */
    h1, .h1 {
        font-size: 4.4rem;
    }

    h2, .h2 {
        font-size: 4rem;
    }

    h3, .h3 {
        font-size: 3.2rem;
    }

    h4, .h4 {
        font-size: 2.4rem;
    }

    h5, .h5 {
        font-size: 2rem;
        margin-bottom: 0;
    }

    h6, .h6 {
        font-size: 1.8rem;
    }
}

.heading-large-light {
    font-size: 3rem;
    line-height: normal;
    font-weight: $light-fw;

    @media screen and (min-width: 35em) { /* 560px */
        font-size: 4.2rem;
        line-height: 1.2
    }

    @media screen and (min-width: 100em) { /* 1600px */
        font-size: 5rem;
    }
}

.heading-medium-light {
    font-size: 2.4rem;
    font-weight: $light-fw;

    @media screen and (min-width: 35em) { /* 560px */
        font-size: 3.2rem;
        line-height: 4.8rem;
    }
}

.heading-light {
    font-weight: $light-fw;
}

.heading-inline {
    display: inline;
    float: left;
    padding: 0;
}

.heading-center {
    text-align: center;
}

.spacer-b {
    margin-bottom: $spacing*4;
}

.heading--subtitle {
    margin-bottom: 0;
}

.subtitle {
    color: $color-grey-05;
    font-weight: $bold-fw;
}

.mainbar {
    h1, .h1 {
        font-size: 4rem;
    }

    h2, .h2 {
        font-size: 3.2rem;
        font-weight: $light-fw;
    }

    h3, .h3 {
        font-size: 2.4rem;
        color: $color-grey-06;
    }

    h4, .h4 {
        font-size: 2rem;
    }

    h5, .h5 {
        font-size: 1.8rem;
    }
}
